import Dashboard from "./pages/dashboard/Dashboard";
import {BrowserRouter, Route, Router, Routes} from "react-router-dom";
import LoginPage from "./pages/loginPage/LoginPage";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";

const App = () => {
    return (
        <BrowserRouter basename={"/isasys"}>
            <Routes>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/dashboard"
                       element={<ProtectedRoute children={<Dashboard/>}/>}/>
            </Routes>
        </BrowserRouter>

    )
}

export default App
