// Loading.js
import React from 'react';
import './Loading.css'; // Optional CSS for styling

const Loading = ({ loading }) => {
    if (!loading) {
        return null;
    }
    return (
        <div className="loading-spinner-center">
            <div className="spinner"></div>
        </div>
    );
};


export default Loading;