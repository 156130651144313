import React from 'react'
import './NavbarVendedor.css'
import { useContext } from 'react'
import ShowedContext from '../../context/showedContext/showedContext'
import AuthContext from "../../context/authContext/authContext";
import ApiService from '../../services/apiService';
import ApiEndpoints from '../../services/apiEndpoints';

const NavbarVendedor = () => {
    const {setShowed} = useContext(ShowedContext);
    const {user} = useContext(AuthContext);

    const handleChange = (value)=>{
        setShowed(value);
    }

    const handleLogOut = async ()=>{
        try{
            
            const response = await ApiService.get(ApiEndpoints.logout)
            document.cookie = 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            // Optionally redirect to home or login page
            window.location.href = process.env.REACT_APP_DOMINIO_FRONT+"/isasys/login";
        }catch(error){

        }
    }

    return (
        <div>
            <nav className='navbar navbar-expand-lg navbar-light bg-light mt-1'>
                <div className='container-fluid'>
                    <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                        <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                            <li className='nav-item m-1 hover-item'>
                                <button onClick={()=>{handleChange('ingresarventas')}} className='nav-link active boton-simple' aria-current='page' >Ingresar venta</button>
                            </li>
                            <li className='nav-item m-1 hover-item' >
                                <button onClick={()=>{handleChange('misventas')}} className='nav-link active boton-simple' aria-current='page'>Mis ventas</button>
                            </li>
                            <li className='nav-item m-1 hover-item' >
                                <button onClick={()=>{handleChange('mireporte')}} className='nav-link active boton-simple' aria-current='page'>Mi reporte</button>
                            </li>
                        </ul>

                        <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                            <li className='nav-item dropdown hover-item'>
                                <button className='nav-link dropdown-toggle boton-simple' id='navbarDropdown' data-bs-toggle='dropdown' aria-expanded='false' href="#">
                                    {!user.nombres?'Nombre del Vendedor':user.nombres}
                                </button>
                                <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                                    <li>
                                        <button
                                         className='dropdown-item boton-simple'
                                         onClick={handleLogOut}
                                         >Salir</button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default NavbarVendedor