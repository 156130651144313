import React from 'react'
import FormVentas from '../../components/formularioVentas/FormVentas'

const FormVentasVendedorPage = () => {
    return (
        <>
            <FormVentas/>
        </>
    )
}

export default FormVentasVendedorPage