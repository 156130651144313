import React, { useContext } from 'react'
import './NavbarManager.css'
import ShowedContext from '../../context/showedContext/showedContext'
import AuthContext from "../../context/authContext/authContext";
import axios from "axios";
import apiEndpoints from "../../services/apiEndpoints";
import apiService from "../../services/apiService";

const NavbarManager = () => {
    const {setShowed} = useContext(ShowedContext);
    const {user} = useContext(AuthContext);

    const handleShowed = (mostrar)=>{
        setShowed(mostrar);
    }

    const handleLogOut = async ()=>{
        try{
            
            const response = await apiService.get(apiEndpoints.logout)
            document.cookie = 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            // Optionally redirect to home or login page
            window.location.href = process.env.REACT_APP_DOMINIO_FRONT+"/isasys/login";
        }catch(error){

        }

    }

    return (
        <div>
            <nav className='navbar navbar-expand-lg navbar-light bg-light mt-1'>
                <div className='container-fluid'>
                    <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                        <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                            <li className='nav-item m-1 hover-item'>
                                <button onClick={() => {
                                    handleShowed('ingresarventas')
                                }} className='nav-link active boton-simple' aria-current='page'>Ingresar venta
                                </button>
                            </li>
                            <li className='nav-item m-1 hover-item'>
                                <button onClick={() => {
                                    handleShowed('misventas')
                                }} className='nav-link active boton-simple' aria-current='page'>Mis ventas
                                </button>
                            </li>
                            <li className='nav-item m-1 hover-item'>
                                <button onClick={() => {
                                    handleShowed('ventas')
                                }} className='nav-link active boton-simple' aria-current='page'>Ventas
                                </button>
                            </li>
                            <li className='nav-item m-1 hover-item' >
                                <button onClick={()=>{handleShowed('mireporte')}} className='nav-link active boton-simple' aria-current='page'>Mi reporte</button>
                            </li>


                            <li className='nav-item m-1 dropdown hover-item'>
                                <button className='nav-link dropdown-toggle boton-simple' id='navbarDropdown'
                                        data-bs-toggle='dropdown' aria-expanded='false' href="#">
                                    Reportes
                                </button>
                                <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                                    <li>
                                        <button onClick={() => {
                                            handleShowed('ventasSemanal');
                                        }}
                                                className='dropdown-item boton-simple'>Ventas Semanal
                                        </button>
                                    </li>
                                </ul>
                            </li>

                            <li className='nav-item m-1 hover-item'>
                                <button onClick={() => {
                                    handleShowed("usuarios");
                                }} className='nav-link active boton-simple' aria-current='page'>Usuarios
                                </button>
                            </li>


                            <li className='nav-item m-1 hover-item'>
                                <button onClick={() => {
                                    handleShowed("precioCredito");
                                }} className='nav-link active boton-simple' aria-current='page'>Precio Crédito
                                </button>
                            </li>


                            <li className='nav-item m-1 hover-item'>
                                <button onClick={() => {
                                    handleShowed("precioPublicidad");
                                }} className='nav-link active boton-simple' aria-current='page'>Precio Publicidad
                                </button>
                            </li>
                        </ul>

                        <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                            <li className='nav-item dropdown hover-item'>
                                <button className='nav-link dropdown-toggle boton-simple' id='navbarDropdown'
                                        data-bs-toggle='dropdown' aria-expanded='false' href="#">
                                    {!user.nombres ? 'Nombre del Manager' : user.nombres}
                                </button>
                                <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                                    <li>
                                        <button className='dropdown-item boton-simple'
                                        onClick={handleLogOut}
                                        >Salir</button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default NavbarManager