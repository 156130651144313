import { useState } from "react";
import UpdatedVentaContext from "./updatedVentaContext";

const UpdatedVentaContextProvider = ({children}) => {
const [updatedVenta, setUpdatedVenta] = useState(0);

  return(
    <UpdatedVentaContext.Provider value={{updatedVenta, setUpdatedVenta}} >
        {children}
    </UpdatedVentaContext.Provider>

  )
}

export default UpdatedVentaContextProvider;