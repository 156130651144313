import DataMostrarContext from "./DataMostrarContext";
import {useState} from "react";

const DataMostrarContextProvider = ({children}) => {

    const [dataMostrar, setDataMostrar] = useState([]);

    return (
        <DataMostrarContext.Provider value={{dataMostrar, setDataMostrar}}>

            {children}

        </DataMostrarContext.Provider>
    )

}

export default DataMostrarContextProvider;