import {InitialSelectsVendedorPaginaContext} from "./InitialSelectsVendedorPaginaContext";
import {useState} from "react";

const InitialSelectsVendedorPaginaContextProvider = ({children})=>{

    const[initialSelectsVendedorPagina, setInitialSelectsVendedorPagina]=useState([])

    return(
        <InitialSelectsVendedorPaginaContext.Provider value={{initialSelectsVendedorPagina, setInitialSelectsVendedorPagina}}>
            {children}
        </InitialSelectsVendedorPaginaContext.Provider>
    );

}

export default InitialSelectsVendedorPaginaContextProvider;