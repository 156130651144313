import React, {useContext, useEffect, useState} from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import ApiService from "../../services/apiService";
import ApiEndpoints from "../../services/apiEndpoints";
import AuthContext from "../../context/authContext/authContext";
import Loading from '../loading/Loading';

const ProtectedRoute = ({children}) => {
    const {user, setUser} = useContext(AuthContext);

    const [logueado, setLogueado] = useState(false);
    const [loading, setLoading] = useState(true);

    const isLoggedIn = async () => {
        const response = await ApiService.get(ApiEndpoints.isLoggedIn);
        setLogueado(response.data);
        setLoading(false);

        //las dos lineas a continuacion son para produccion
        //const userDataPromise = await ApiService.get(ApiEndpoints.userLoggedData);
        //setUser(userDataPromise.data);


    }

    useEffect(() => {
        isLoggedIn();
    }, []);

    if(loading){
        return <Loading loading={loading}/>
    }

    if (!logueado) {
        return (
            <Navigate to='/login'/>
        )
    } else {
        return children;
    }

}

export default ProtectedRoute