import { useEffect, useState } from "react";
import ApiEndpoints from "../../services/apiEndpoints";
import ApiService from "../../services/apiService";
import "./TablaPrecioCredito.css";
import { DataGrid } from "@mui/x-data-grid";

const TablaPrecioCredito = () => {

    const[rows, setRows] = useState([]);

    const fetchPreciosCredito = async () => {
        const response = await ApiService.getAll(ApiEndpoints.precioCredito)
        console.log("Precio creidito");
        console.log(response.data);
        
        const normalizeRows = response.data.map(
            (precioCredito) => {
               return {
                id:precioCredito.id,
                usuarioNombres:precioCredito.usuarioDTO.nombres+" "+precioCredito.usuarioDTO.apellidos,
                email:precioCredito.usuarioDTO.email,
                pagina:precioCredito.paginaQueVendioDTO.pagina,
                precio:precioCredito.precio,
                porcentaje:precioCredito.porcentaje,
                vigenciaDesde:precioCredito.vigenciaDesde
               }
            }
        )

        setRows(normalizeRows);
    }

    const columns = [
        {
            field: "id",
            width: 50
        },
        {
            field: "usuarioNombres",
            headerName:"Nombre",
            width: 200
        },
        {
            field: "email",
            headerName:"Email",
            width: 200
        },
        {
            field: "pagina",
            headerName:"Pagina",
            width: 200
        },
        {
            field: "precio",
            headerName:"Precio",
            width: 80
        },
        {
            field: "porcentaje",
            headerName:"Porcentaje de Ganancia",
            width: 80
        },
        {
            field: "vigenciaDesde",
            headerName:"Vigencia desde",
            width: 120
        },

    ]

    useEffect(
        () => {
            fetchPreciosCredito();
        }, []
    )

    return (
        <div className="contenedor-tabla-precio-credito">
            Tabla Precio Credito
            <DataGrid
            columns={columns}
            rows={rows}
            />
        </div>
    )
}

export default TablaPrecioCredito;