import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ApiService = {
    async get(endpoint, parametros){
        console.log(parametros);    
        try{
            const pars = {
                params:parametros,
                withCredentials:true
            }
            const response= await axios.get(API_BASE_URL+endpoint, pars);
            return response;
        }catch (error){
            throw new Error("Se falló en ejecutar get en "+endpoint);
        }
    },
    async post(endpoint, pars){
        try {
            const response = await axios.post(API_BASE_URL+endpoint, pars,{withCredentials:true});
            return response;
        } catch (error) {
            throw new Error("Se falló en ejecutar el post en "+endpoint);
        }
    },
    async postForm(endpoint, formData){
      try {
          const response = await axios.post(
              API_BASE_URL+endpoint,
              formData,
              {
                  headers:{
                      'Content-Type':'multipart/form-data'
                  },
                  withCredentials:true
              }
          );
          return response;
      }  catch (error){
          throw new Error("Se fallo en hacer el post al endpoint "+endpoint)
      }
    },
    async getAll(endpoint){
        try {
            const response = await axios.get(API_BASE_URL+endpoint,{withCredentials:true});
            return response;
        } catch (error) {
            throw new Error("Se falló en ejecutar getAll en "+endpoint);
        }
    },
    async getAllPageSize(endpoint, page, size, sortBy = null, sortDirection = null, filterField = null, filterValue = null) {
        try {
            const response = await axios.get(
                API_BASE_URL + endpoint,
                {
                    params: {
                        page: page,
                        size: size,
                        sortBy: sortBy,               // Send sort field
                        direction: sortDirection,      // Send sort direction
                        filterField: filterField,      // Send filter field
                        filterValue: filterValue       // Send filter value
                    },
                    withCredentials: true
                }
            );
            return response;
        } catch (error) {
            throw new Error("Se falló en ejecutar getAll en " + endpoint);
        }
    }
    ,
    async create(endpoint, object){
        try {
            const response =await axios.post(API_BASE_URL+endpoint, object,  {withCredentials:true} );
            return response;
        } catch (error) {
            console.log(error)
            throw new Error(error.response.data.error + " Se falló en crear objeto en "+endpoint);
        }
    },
    async update(endpoint, object){
            const response = await axios
            .put(API_BASE_URL+endpoint, 
                object,
            {withCredentials:true}
            )
            .catch(error=>{
                console.log("Error al guardar los datos ",error.response.data);
                return error.response.data;
            })
            return response;
    },
    async delete(endpoint){
        const response = await axios
        .delete(API_BASE_URL+endpoint, {withCredentials:true})
        return response;
    }
}

export default ApiService;