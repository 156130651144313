const FilterSelectMenuPagina = ({
                                    handleCheckChangePaginas,
                                    initialSelecteds,
                                    index,
                                    indexMonth,
                                    pagina})=>{
    return (
        <div className="containerFilterMenuPagina">
            <input
                id="pagina"
                name="pagina"
                type="checkbox"
                checked={initialSelecteds[index]["paginas"][indexMonth]["isPaginaSelected"]}
                onChange={handleCheckChangePaginas}
            />
            <label htmlFor="pagina">{pagina}</label>
        </div>
    );
}

export default FilterSelectMenuPagina;
