import React, {useState} from "react";
import ShowedContext from "./showedContext";

const ShowedContextProvider = ({ children }) => {

    const [showed, setShowed] = useState('ingresarventas');

    return (
        <ShowedContext.Provider value={{showed, setShowed}}>
            {children}
        </ShowedContext.Provider>
    )
}

export default ShowedContextProvider