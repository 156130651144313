import * as React from 'react';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import ApiService from '../../services/apiService';
import {
    listadoLabelsVentaDTOVendedor
} from '../formularioVentas/Validaciones';
import './TablaVentasVendedor.css'
import {useContext} from "react";
import AuthContext from "../../context/authContext/authContext";
import Loading from "../loading/Loading";
import UpdatedVentaContext from '../../context/updatedVentaContext/updatedVentaContext';


export default function TablaVentasVendedor() {
    const {user} = useContext(AuthContext);
    const email = !user.email?'':user.email;

    const {updatedVenta, setUpdatedVenta}=useContext(UpdatedVentaContext);

  

    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    const[loading, setLoading] = React.useState(false);

    const fetchVentas = async () => {
        setLoading(true);
        const response = await ApiService.getAll("/ventas/email/"+email);

        const columnas = Object
            .entries(listadoLabelsVentaDTOVendedor)
            .map(([field, headerName]) =>
            ({
                field,
                headerName,
                width: 150
            })
        )

        columnas.push({
            field: "marcaTemporal",
            headerName: "Marca Temporal",
            width: 150,
        })
        setColumns(columnas);

        const filas = response.data;
        setRows(filas);
        setLoading(false);
    }

    React.useEffect(
        () => {
            fetchVentas();
        }
        , [updatedVenta])


    return (
        <div className='contenedor-datagrid' style={{height: 750, width: '100%'}}>
            {
                loading?
                    <Loading/>:
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        disableRowSelectionOnClick
                        density='compact'
                        slots={{
                            toolbar: GridToolbar,
                        }}
                    />
            }
        </div>
    );
}