class FiltroSemanasImplementation {
    constructor() {
    }

    //Semana 14 Desde 2024-04-01 Hasta 2024-04-07
    extractYears(arrayFechas) {
        const arrayYears = arrayFechas.map((semanaFecha) => {
                return semanaFecha.split(" ")[3].split("-")[0];
            }
        )
        const arrayYearsSet = new Set();
        arrayYears.sort().forEach(
            (year) => {
                arrayYearsSet.add(year)
            }
        )
        return Array.from(arrayYearsSet);
    }

    createObjectYears(arrayYears) {
        const objectYears = {};
        arrayYears.forEach(
            (year) => {
                objectYears[year] = {}
            }
        )
        return objectYears;
    }

    //Semana 14 Desde 2024-04-01 Hasta 2024-04-07
    addMonthsToYears(arrayFechas, objectYears) {
        Object.entries(objectYears).forEach(
            ([year]) => {
                const setMonths = new Set();
                arrayFechas.forEach((fecha) => {
                    const yearFecha = fecha.split(" ")[3].split("-")[0];
                    const month = fecha.split(" ")[3].split("-")[1];

                    if (year === yearFecha) {
                        setMonths.add(month);
                    }
                });
                const arrayMonths = Array.from(setMonths);
                arrayMonths.sort();
                arrayMonths.forEach((month) => {
                    objectYears[year][month] = []
                })
            }
        )
        return objectYears;
    }

    extractDate(str) {
        // Extract the date string using a regular expression
        const match = str.match(/Desde (\d{4}-\d{2}-\d{2})/);
        if (match) {
            return new Date(match[1]);
        }
        return null;
    }

    sortWeeks(arrayWeeks) {
        return arrayWeeks.sort((a, b) => {
            const dateA = this.extractDate(a);
            const dateB = this.extractDate(b);

            if (dateA && dateB) {
                // Both dates are valid
                if (dateA < dateB) {
                    return -1;
                } else if (dateA > dateB) {
                    return 1;
                } else {
                    return 0;
                }
            } else if (dateA && !dateB) {
                // dateA is valid, dateB is not
                return -1;
            } else if (!dateA && dateB) {
                // dateA is not valid, dateB is
                return 1;
            } else {
                // Neither date is valid
                return 0;
            }
        });
    }


    //Semana 14 Desde 2024-04-01 Hasta 2024-04-07
    addWeekDays(arrayFechas, objectYears) {
        arrayFechas.forEach(
            (week) => {
                const year = week.split(" ")[3].split("-")[0];
                const month = week.split(" ")[3].split("-")[1];
                if (!objectYears[year][month].includes(week)) {
                    objectYears[year][month].push(week)
                }
            }
        )

        Object.entries(objectYears).forEach(
            ([year, months]) => {
                Object.entries(months).forEach(
                    ([month, semanas]) => {
                        objectYears[year][month] = this.sortWeeks(semanas);
                    }
                )
            }
        )

        return objectYears;
    }

    initializeSelectsSemanas(jsonTreeFechas) {
        return Object.entries(jsonTreeFechas).map(
            ([year, months]) => {
                return (
                    {
                        "indexYear": year, "isYearSelected": true,
                        "months": Object.entries(months).map(
                            ([mes, dias]) => {
                                return (
                                    {
                                        "month": mes, "isMonthSelected": true, "days": dias.map(
                                            (dia) => {
                                                return {"day": dia, "isDaySelected": true}
                                            }
                                        )
                                    }
                                )
                            }
                        )
                        .sort((a,b)=>a.month-b.month)
                    }
                )
            }
        )
    }

    getSemanas = (data) => {
        const listadoSemanas = [];
        if (data.length > 0) {
            data.forEach((obj) => {
                listadoSemanas.push(obj["semana"]);
            })
        }
        return listadoSemanas;
    }



    createObjectInitializeSelectsSemanas(data){

        const semanas = this.getSemanas(data);

        const arrayYears = this.extractYears(semanas);
        const objectYears = this.createObjectYears(arrayYears);
        this.addMonthsToYears(semanas, objectYears);
        this.addWeekDays(semanas, objectYears);

        return objectYears;

    }


}

export default FiltroSemanasImplementation;