import "./LoginPage.css"
import {FcGoogle} from "react-icons/fc";
import {useState} from "react";
import apiService from "../../services/apiService";
import apiEndpoints from "../../services/apiEndpoints";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

const LoginPage = ()=>{
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const googleLogin = ()=>{
        window.location.href = process.env.REACT_APP_API_BASE_URL+"/oauth2/authorization/google"
    }

    const handleOnChange = (event, parametro)=>{
        if(parametro==="username"){
            setUsername(event.target.value)
        }
        if(parametro==="password"){
            setPassword(event.target.value)
        }
    }

    const handleClickIngresar = async ()=>{
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        try{
            const response = await apiService.postForm(apiEndpoints.loginUsernamePassword,formData);
            if(response.data===true){
                navigate("/dashboard");
            }
        }catch(error){
            console.log("Error en la autenticación")
            Swal.fire(
                {
                    title:"Error",
                    text:"Error en los datos ingresados",
                    icon:"error"
                }
            )
        }
    }

    return(
        <div className="containerLogin">

            <div className="loginCard">
            
                <div className={"titulo-acceder"}>Version 6nov</div>
                <div className={"contenedor-login-userpass"}>
                    <div className={"input-username"}>
                        <input type="text" placeholder={"Email"}
                        onChange={(event)=>handleOnChange(event,"username")}
                        onKeyDown ={ (event)=> {
                            if(event.key==="Enter"){
                                handleClickIngresar();
                            }
                         }}
                        />
                    </div>
                    <div className={"input-password"}>
                        <input type="password" placeholder={"Contraseña"}
                               onChange={(event)=>handleOnChange(event,"password") }
                               onKeyDown ={ (event)=> {
                                if(event.key==="Enter"){
                                    handleClickIngresar();
                                }
                             }}
                        />
                    </div>
                    <div className={"input-button"}>
                        <button onClick={handleClickIngresar}>INGRESAR</button>
                    </div>
                </div>

                <div className={"text-o-inicia-sesion"}>O inicia sesión con Google</div>

                <button onClick={googleLogin}><FcGoogle/> Login con Google</button>
            </div>
        </div>
    );
}

export default LoginPage;