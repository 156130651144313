
const FilterSelectTipoVenta = ({handleCheckChangeTipoVenta, initialSelectedsTipoVenta, tipoVenta, indice})=>{
    return (
        <div className="containerFilterMenu">
            <svg className="icon" width="24" height="24" viewBox="0 0 15 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg"
            >
            </svg>
            <input
                id="tipoventa"
                name="tipoventa"
                type="checkbox"
                className="form-check-input"
                checked={initialSelectedsTipoVenta[indice]["isTipoVentaSelected"]}
                onChange={handleCheckChangeTipoVenta}
            />
            <label className="form-check-label"  htmlFor="tipoventa">{tipoVenta}</label>
        </div>
    );
}

export default FilterSelectTipoVenta;
