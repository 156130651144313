import React, { useContext, useEffect } from 'react'
import TablaVentasVendedor from '../../components/tablaVentasVendedor/TablaVentasVendedor'
import UpdatedVentaContext from '../../context/updatedVentaContext/updatedVentaContext';

const TableVentasVendedorPage = () => {
  const {updatedVenta, setUpdatedVenta}=useContext(UpdatedVentaContext);

  useEffect(
    ()=>{
console.log("Me estoy actualizando");

    }, [updatedVenta]
  )

  return (
    <>
            <TablaVentasVendedor updatedVenta={updatedVenta} />
    </>
  )
}

export default TableVentasVendedorPage