
const FilterSelectMenuDays = ({handleShowDays, handleCheckChangeMonths,initialShows, initialSelecteds, index,indexMonth, mes})=>{
    const meses = {
        "01":"Enero",
        "02":"Febrero",
        "03":"Marzo",
        "04":"Abril",
        "05":"Mayo",
        "06":"Junio",
        "07":"Julio",
        "08":"Agosto",
        "09":"Septiembre",
        "10":"Octubre",
        "11":"Noviembre",
        "12":"Diciembre",
    }
    return (
        <div className="containerFilterMenu">
            <svg className="icon" width="24" height="24" viewBox="0 0 15 24"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
                 onClick={handleShowDays}
            >
                {
                    !initialShows[index]["months"][indexMonth]["showDays"] ?
                        <path d="M7 10L12 15L17 10H7Z"
                              fill="currentColor"/> :
                        <path d="M7 14L12 9L17 14H7Z" fill="currentColor"/>
                }

            </svg>
            <input
                id="month"
                name="month"
                type="checkbox"
                className="form-check-input"
                checked={initialSelecteds[index]["months"][indexMonth]["isMonthSelected"]}
                onChange={handleCheckChangeMonths}
            />
            <label className="form-check-label" htmlFor="month">{meses[mes]}</label>
        </div>
    );
}

export default FilterSelectMenuDays;
