import InitialSelectsContext    from "./InitialSelectsContext";
import {useState} from "react";

const InitialSelectsContextProvider = ({children})=>{

    const [initialSelecteds, setInitialSelecteds] = useState([]);

    return (
        <InitialSelectsContext.Provider value={{initialSelecteds, setInitialSelecteds}}>

            {children}

        </InitialSelectsContext.Provider>
    )

}

export default InitialSelectsContextProvider;