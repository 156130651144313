import React from 'react'
import TablaVentasSubManager from '../../components/tablaVentasSubManager/TablaVentasSubManager'

const TableVentasSubManagerPage = () => {
  return (
    <>
        
            <TablaVentasSubManager/>
        
    </>
  )
}

export default TableVentasSubManagerPage