
export const objetoVendedorPagina = (data) => {

    /*
    objVendPag = {
        idUsuario:{
            nombreCompleto:nombres+apellidos,
            paginas:[pagina1, pagina2]
        }
    }
    {
      '2': { nombreCompleto: 'DEIBY CURREA', paginas: [ 'MGNT STR', 'WS' ] },
      '3': { nombreCompleto: 'EVER SANCHEZ', paginas: [ 'CNLSPRM DX' ] },
      '5': {
        nombreCompleto: 'JOGELIS PIÑERO',
        paginas: [ 'LTVM - PRINCIPAL', 'LTVM SC', 'WS' ]
      }
    }
    */

    let objVendPag = {}

    data.map(
        (obj) => {
            if (objVendPag[obj['idUsuario']]) {
                if (!objVendPag[obj['idUsuario']]['paginas'].includes(obj['paginaVendida'])) {
                    objVendPag[obj['idUsuario']]['paginas'].push(obj['paginaVendida'])
                }
            } else {
                objVendPag[obj['idUsuario']]={}
                objVendPag[obj['idUsuario']]['nombreCompleto'] = obj['usuarioNombres'] + " " + obj['usuarioApellidos'];
                objVendPag[obj['idUsuario']]['paginas'] = []
                objVendPag[obj['idUsuario']]['paginas'].push(obj['paginaVendida'])
            }
        }
    );

    return objVendPag;
}

export const initializeShowsVendedorPagina = (objVendedorPagina) => {
    return Object.entries(objVendedorPagina).map(
        ([idUsuario, objNombrePaginas]) => {
            return (
                {
                    "indexUsuario": idUsuario,
                    "nombreCompleto":objNombrePaginas["nombreCompleto"],
                    "showPaginas": false,
                    "paginas": (objNombrePaginas["paginas"]).map(
                        (pagina) => {
                            return (
                                {"indexPagina": pagina, "showPaginas": false}
                            )
                        }
                    )
                }
            )
        }
    )
}

export const initializeSelectsVendedorPagina = (objVendedorPagina) => {
    return Object.entries(objVendedorPagina).map(
        ([idUsuario, objNombrePaginas]) => {
            return (
                {
                    "indexUsuario": idUsuario,
                    "nombreCompleto":objNombrePaginas["nombreCompleto"],
                    "isUsuarioSelected": true,
                    "paginas": objNombrePaginas["paginas"].map(
                        (pagina) => {
                            return (
                                {
                                    "pagina": pagina,
                                    "isPaginaSelected": true
                                }
                            )
                        }
                    )
                }
            )
        }
    )
}
