const ApiEndpoints = {
    urlBase:"http://localhost:8080",
    loginUsernamePassword:"/loginusernamepassword",
    isLoggedIn:"/isLoggedIn",
    userLoggedData:"/userloggeddata",
    mesesVendidos:"/mesesvendidos",
    valorPagado:"/valorpagadoporelcliente",
    monedaPago:"/monedaspagocliente",
    paisCliente:"/paisesclientes",
    porDondePago:"/pordondepagoelcliente",
    tipoVenta:"/tiposventas",
    cantidadSmart:"/cantidadsmartoneiptvactivados",
    paginaQueVendio:"/paginasquevendio",
    paginaQueVendioEmail:"/paginasquevendio/email",
    paginaQueVendioIdUsuario:"/paginasquevendio/manager",
    appsCliente:"/appscliente",
    venta:"/ventas",
    reportes:{
        ventasSemanalManager:"/reportes/ventas-semanal",
        ventasSemanalVendedor:"/reportes/ventas-semanal-vendedor"
    },
    logout:"/logout",
    usuario:"/usuarios",
    roles:"/roles",
    precioCredito:"/preciocredito",
    precioPublicidadNumeroSemana:"/preciopublicidad/numerosemana",
    semana:"/semana",
    precioPublicidad:"/preciopublicidad"
}

export default ApiEndpoints;