import _ from 'lodash';

export const calculateAccumulatedBySeller = (data) => {

    const idUsuario = "idUsuario";

    const groupedData = _.groupBy(data, idUsuario);

    const result = _.map(groupedData, (items, userId) => {
        return {
            idUsuario: parseInt(userId),
            usuarioNombres: items[0].usuarioNombres,
            usuarioApellidos: items[0].usuarioApellidos,
            precioPublicidad: _.sumBy(items, 'precioPublicidad'),
            totalValorPagadoPorElCliente: _.sumBy(items, 'totalValorPagadoPorElCliente'),
            totalComision: _.sumBy(items, 'totalComision'),
            totalGananciaNeta: _.sumBy(items, 'totalGananciaNeta'),
            totalGananciaNetaVendedor: _.sumBy(items, 'totalGananciaNetaVendedor'),
            totalGananciaNetaManager: _.sumBy(items, 'totalGananciaNetaManager'),
            gananciaFinalVendedor: _.sumBy(items, 'gananciaFinalVendedor')
        };
    });

    return result;

}

export const calculateAccumulatedBySellerAndPage = (data) => {

    // Group by both idUsuario and paginaVendida
    const groupedByUserAndPage = _.groupBy(data, item => `${item.idUsuario}-${item.paginaVendida}`);

// Sum the values for each group
    const result = _.map(groupedByUserAndPage, (items, key) => {
        const [idUsuario, paginaVendida] = key.split('-');

        return {
            idUsuario: parseInt(idUsuario),
            usuarioNombres: items[0].usuarioNombres,
            usuarioApellidos: items[0].usuarioApellidos,
            paginaVendida: paginaVendida,
            precioPublicidad: _.sumBy(items, 'precioPublicidad'),
            totalValorPagadoPorElCliente: _.sumBy(items, 'totalValorPagadoPorElCliente'),
            totalComision: _.sumBy(items, 'totalComision'),
            totalGananciaNeta: _.sumBy(items, 'totalGananciaNeta'),
            totalGananciaNetaVendedor: _.sumBy(items, 'totalGananciaNetaVendedor'),
            totalGananciaNetaManager: _.sumBy(items, 'totalGananciaNetaManager'),
            gananciaFinalVendedor: _.sumBy(items, 'gananciaFinalVendedor')
        };
    });

    return result

}

export const calculateAccumulatedByMonth = (dataMostrar, campo) => {
    let arrAccumalated = {};

    let contador = [];

    dataMostrar.map(
        (row) => {


            const arr = row['inicioSemana'].split('-')

            const year = arr[0]
            const month = arr[1]
            const day = arr[2]


            if (arrAccumalated[year] !== undefined) {
                if(arrAccumalated[year][month]!==undefined){
                    arrAccumalated[year][month] = arrAccumalated[year][month] + parseFloat(row[campo])
                }
                else{
                    arrAccumalated[year][month] = parseFloat(row[campo])
                }
            } else {
                arrAccumalated[year] = {}
                arrAccumalated[year][month] = parseFloat(row[campo])
            }
        }
    )

    return arrAccumalated;
}

export const processData = (data) => {
    // Create a composite key for grouping
    const createCompositeKey = (item) => `${item.semana}-${item.idUsuario}`;

    // Group by composite key
    const groupedData = _.groupBy(data, createCompositeKey);

    // Sum gananciaFinalVendedor for each group
    const result = _.map(groupedData, (group, key) => {
        const keyParts = key.split('-');
        const semana = keyParts.slice(0, -1).join('-'); // Rejoin all parts except the last one for semana
        const idUsuario = parseInt(keyParts[keyParts.length - 1], 10); // The last part is idUsuario
        return {
            semana,
            idUsuario,
            precioPublicidad: _.sumBy(group, "precioPublicidad"),
            totalValorPagadoPorElCliente: _.sumBy(group, "totalValorPagadoPorElCliente"),
            "totalComision": _.sumBy(group, "totalComision"),
            "totalGananciaNeta": _.sumBy(group, "totalGananciaNeta"),
            "totalGananciaNetaVendedor": _.sumBy(group, "totalGananciaNetaVendedor"),
            "totalGananciaNetaManager": _.sumBy(group, "totalGananciaNetaManager"),
            gananciaFinalVendedor: _.sumBy(group, 'gananciaFinalVendedor'),
        };
    });

    return result;
};

/**
 * Esta función se aplica sólo a los totales mensuales por vendedor y pagína, es decir que la fuente de esto
 * es calulateAccumulatedBySellerAndPage
 * @param {array} totales - son los valores totales calculados por  calulateAccumulatedBySellerAndPage
 */
export const calcularDescuentoCripto = (totales, descuento) => {
    let copyTotales = [...totales];

    copyTotales.map(
        (row) => {
            if (row['gananciaFinalVendedor'] > 0) {
                row['gananciaFinalVendedor'] = row['gananciaFinalVendedor'] - descuento * row['gananciaFinalVendedor']
            }
        }
    )

    return copyTotales;
}

export const calculateTotalGananciaFinalVendedor = (data)=>{
    let total = 0;

    data.forEach(
        (row)=>{
            total = total + row['gananciaFinalVendedor']
        }
    )

    return total;
}

export const calculateTotalGananciaNetaManager = (data)=>{
    let total = 0;

    data.forEach(
        (row)=>{
            total = total + row['totalGananciaNetaManager']
        }
    )

    return total;
}

export const calculateTotalPublicidad = (data)=>{
    let total = 0;

    data.forEach(
        (row)=>{
            total = total +  row['precioPublicidad']
        }
    )

    return total;
}
