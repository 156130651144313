import React, { useContext, useEffect } from 'react';
import ContenedorReporteVentasSemanalVendedor from '../../components/reportes/vendedor/contenedorreporteventassemanalvendedor/ContenedorReporteVentasSemanalVendedor';
import UpdatedVentaContext from '../../context/updatedVentaContext/updatedVentaContext';


function TableReportesVentasSemanalVendedorPage(props) {
    return (
        <>
            <ContenedorReporteVentasSemanalVendedor />
        </>
    );
}

export default TableReportesVentasSemanalVendedorPage;