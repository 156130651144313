import "./TablaUsuarios.css"
import { DataGrid } from "@mui/x-data-grid";
import apiService from "../../services/apiService";
import apiEndpoints from "../../services/apiEndpoints";
import { useEffect, useState } from "react";
import { aplicarFiltros } from "../reportes/manager/contenedorreporteventassemanalmanager/utils/Filtros";
import ModalAgregarUsuario from "./components/modalagregarusuario/ModalAgregarUsuario";
import Swal from "sweetalert2";
import ModalEditarUsuario from "./components/modaleditarusuario/ModalEditarUsuario";

const TablaUsuarios = () => {
    const [showModalAgregarUsuario, setShowModalAgregarUsuario] = useState(false);
    const [showModalEditarUsuario, setShowModalEditarUsuario] = useState(false);
    const [rows, setRows] = useState([]);
    const [idUsuarioEditar, setIdUsuarioEditar] = useState(-1);

    const fetchUsuarios = async () => {
        const response = await apiService.get(apiEndpoints.usuario);
        console.log(response.data);
        setRows(response.data);
    }

    const openAgregarUsuario = () => {
        setShowModalAgregarUsuario(true);
    }

    const closeAgregarUsuario = () => {
        setShowModalAgregarUsuario(false);
    }

    const openEditarUsuario = (idusuario) => {
        setIdUsuarioEditar(idusuario);
        setShowModalEditarUsuario(true);
    }

    const closeEditarUsuario = () => {
        setShowModalEditarUsuario(false);
    }



    const handleEliminar = async (id) => {
        const response = await apiService.delete(apiEndpoints.usuario + "/" + id);
        if (response.data) {
            Swal.fire(
                {
                    title: "Error",
                    text: response.data.error,
                    icon: "error"
                }
            )
        } else {
            fetchUsuarios();
            Swal.fire(
                {
                    title: "Usuario borrado",
                    text: "El usuario ha sido borrado con éxito",
                    icon: "success"
                }
            )
        }
    }

    const columns = [
        {
            field: "idusuario", width: 80
        },
        { field: "nombres", width: 100 },
        { field: "apellidos", width: 100 },
        { field: "email", width: 150 },
        { field: "roles", width: 150 },
        { field: "paginaquevendios", width: 350 },
        { field: "activo" },
        {
            field: "edit",
            headerName: "Editar",
            width: 100,
            renderCell: (params) => {
                return <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => openEditarUsuario(params.row.idusuario) }
                >
                    <i className="icon-pencil"></i>
                </button>
            }
        },
        {
            field: "delete",
            headerName: "Eliminar",
            width: 100,
            renderCell: (params) => {
                return <button
                    onClick={() => handleEliminar(params.id)}
                    type="button" className="btn btn-danger"
                >
                    <i class="icon-trash"></i>
                </button>
            }
        }
    ]

    const getRowId = (row) => {
        return row.idusuario;
    }

    useEffect(() => {
        fetchUsuarios();
    }, []);

    return (
        <div className={"contenedor-usuarios"}>
            <ModalAgregarUsuario
                show={showModalAgregarUsuario}
                fetchUsuarios={fetchUsuarios}
                closeAgregarUsuario={closeAgregarUsuario}
            />
            <ModalEditarUsuario
                show={showModalEditarUsuario}
                idusuario={idUsuarioEditar}
                fetchUsuarios={fetchUsuarios}
                closeEditarUsuario={closeEditarUsuario}
            />
            <div className={"contenedor-agregar-usuario"}>
                <button
                    onClick={openAgregarUsuario}
                    type="button"
                    className="btn btn-primary">
                    Agregar usuario
                </button>
            </div>
            <div className={"contenedor-tabla-usuarios"}>
                <DataGrid
                    getRowId={getRowId}
                    columns={columns}
                    rows={rows} />
            </div>
        </div>

    )
}

export default TablaUsuarios;