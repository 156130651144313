import {useContext, useEffect, useState} from "react";
import DataContext from "../context/DataContext";
import {getTiposVenta} from "./Utils";
import FilterSelectTipoVenta from "./filterselectmenu/FilterSelectTipoVenta";
import axios from "axios";
import DataMostrarContext from "../context/DataMostrarContext";
import InitialSelectsContext from "../context/InitialSelectsContext";
import {InitialSelectsVendedorPaginaContext} from "../context/InitialSelectsVendedorPaginaContext";
import {aplicarFiltros} from "../../utils/Filtros";
import FiltroSemanasImplementation from "../filtrosemanas/FiltroSemanasImplementation";
import {initializeSelectsVendedorPagina, objetoVendedorPagina} from "../filtrovendedorpagina/Utils";
import ApiService from "../../../../../../services/apiService";
import ApiEndpoints from "../../../../../../services/apiEndpoints";
import FechaInicialFinalContext from "../context/FechaInicialFinalContext";


const filtroSemanasImpl = new FiltroSemanasImplementation();

const FiltroTipoVenta = () => {

    const { fechas, setFechas } = useContext(FechaInicialFinalContext);

    const {data, setData} = useContext(DataContext);
    const {dataMostrar, setDataMostrar} = useContext(DataMostrarContext);
    const {initialSelecteds, setInitialSelecteds} = useContext(InitialSelectsContext)
    const {
        initialSelectsVendedorPagina,
        setInitialSelectsVendedorPagina
    } = useContext(InitialSelectsVendedorPaginaContext)

    const [tiposVenta, setTiposVenta] = useState([]);
    const [initialSelectedsTipoVenta, setInitialSelectedsTipoVenta] = useState([]);

    const inicializarEstados = async () => {
        const tiposVentas = await getTiposVenta();
        setTiposVenta(tiposVentas);
        asignarInitialSelectsTipoVenta(tiposVentas);
    }
    const asignarInitialSelectsTipoVenta = (tiposVentas) => {
        setInitialSelectedsTipoVenta(
            tiposVentas.map(
                (tipoVenta) => {
                    return {
                        "indexTipoVenta": tipoVenta["idtipoventa"],
                        "tipo": tipoVenta["tipo"],
                        "isTipoVentaSelected": true
                    }
                })
        )
    }


    const handleCheckChangeTipoVenta = async (index, initialSelectedsTipoVenta) => {
        const prevInitialSelectedsTipoVenta = [...initialSelectedsTipoVenta];
        prevInitialSelectedsTipoVenta[index]['isTipoVentaSelected'] = !prevInitialSelectedsTipoVenta[index]["isTipoVentaSelected"] === true;
        setInitialSelectedsTipoVenta(prevInitialSelectedsTipoVenta);

        let arregloTiposVenta = [];
        prevInitialSelectedsTipoVenta.forEach(
            (tipoVenta) => {
                if (tipoVenta['isTipoVentaSelected']) {
                    const indexTipoVenta = tipoVenta['indexTipoVenta'];
                    arregloTiposVenta.push(indexTipoVenta);
                }
            }
        )

        const cadenaTiposVenta = arregloTiposVenta.join(",")

        const response = await ApiService.get(ApiEndpoints.reportes.ventasSemanalManager,
            {
                idsTipoVenta: cadenaTiposVenta,
                dateInicial: fechas['fechaInicial'],
                dateFinal: fechas['fechaFinal']
            }
        )

        setData(
            (prevData) => {
                const newData = response.data;
                setDataMostrar(newData);
                return newData;
            }
        );
    }

    useEffect(() => {
        inicializarEstados();
    }, []);

    return (
        <>
            <div className="containerFilterTipoVenta">
                {tiposVenta.length > 0 ?
                    tiposVenta.map(
                        (tipoventa, index) => {
                            return <FilterSelectTipoVenta
                                handleCheckChangeTipoVenta={
                                    () => {
                                        handleCheckChangeTipoVenta(index, initialSelectedsTipoVenta)
                                    }
                                }
                                initialSelectedsTipoVenta={initialSelectedsTipoVenta}
                                tipoVenta={tipoventa['tipo']}
                                indice={index}
                            />
                        }
                    ) : ''
                }
            </div>
        </>
    );
}

export default FiltroTipoVenta;