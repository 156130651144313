import React, { useContext, useEffect, useState } from 'react'
import { Input } from '@mui/material'
import { FormLabel } from '@mui/material'
import ContainerInput from './ContainerInput'
import Swal from 'sweetalert2'
import { listadoIdLabelObligatorios, listadoLabelsTodosLosCampos } from './Validaciones'
import ApiService from '../../services/apiService'
import ApiEndpoints from '../../services/apiEndpoints'
import Loading from '../loading/Loading'
import { useNavigate } from 'react-router-dom'
import updatedVentaContext from '../../context/updatedVentaContext/updatedVentaContext'

const FormVentas = () => {
    const { updatedVenta, setUpdatedVenta } = useContext(updatedVentaContext);

    const [loading, setLoading] = useState(true);


    const [cantidadMeses, setCantidadMeses] = useState([]);
    const [valorPagado, setValorPagado] = useState([]);
    const [monedaPago, setMonedaPago] = useState([]);
    const [paises, setPaises] = useState([]);
    const [porDondePago, setPorDondePago] = useState([]);
    const [tipoVenta, setTipoVenta] = useState([]);
    const [cantidadSmartOne, setCantidadSmartOne] = useState([]);
    const [paginaQueVendio, setPaginaQueVendio] = useState([]);
    const [appsCliente, setAppsCliente] = useState([]);

    const [appChecked, setAppChecked] = useState([]);

    const [dataFormulario, setDataFormulario] = useState(
        {
            fechaVenta: "",
            fechaVencimientoServicio: "",
            nombreApellidoDelCliente: "",
            usuarioClienteIPTV: -1,
            mesesVendidos: -1,
            valorPagado: "",
            monedaPago: "",
            pais: -1,
            porDondePago: -1,
            tipoVenta: -1,
            cantidadSmart: -1,
            paginaQueVendio: -1,
            groupAppsCliente: appChecked
        }
    );

    const fetchCantidadMeses = async () => {
        const response = await ApiService.getAll(ApiEndpoints.mesesVendidos);
        setCantidadMeses(response.data);
    }

    const fetchValorPagado = async () => {
        const response = await ApiService.getAll(ApiEndpoints.valorPagado);
        setValorPagado(response.data);
    }

    const fetchMonedaPago = async () => {
        const response = await ApiService.getAll(ApiEndpoints.monedaPago);
        setMonedaPago(response.data);
    }

    const fetchPaises = async () => {
        const response = await ApiService.getAll(ApiEndpoints.paisCliente);
        setPaises(response.data);
    }

    const fetchPorDondePago = async () => {
        const response = await ApiService.getAll(ApiEndpoints.porDondePago);
        setPorDondePago(response.data);
    }

    const fetchTipoVenta = async () => {
        const response = await ApiService.getAll(ApiEndpoints.tipoVenta);
        setTipoVenta(response.data);
    }

    const fetchCantidadSmartOne = async () => {
        const response = await ApiService.getAll(ApiEndpoints.cantidadSmart);
        setCantidadSmartOne(response.data);
    }

    const fetchPaginaQueVendio = async () => {
        const response = await ApiService.get(ApiEndpoints.paginaQueVendioEmail);
        setPaginaQueVendio(response.data);
    }

    const fetchAppsCliente = async () => {
        const response = await ApiService.getAll(ApiEndpoints.appsCliente);
        setAppsCliente(response.data);
    }

    const fetchAllData = () => {
        fetchPaises();
        fetchCantidadMeses();
        fetchValorPagado();
        fetchMonedaPago();
        fetchPorDondePago();
        fetchTipoVenta();
        fetchCantidadSmartOne();
        fetchPaginaQueVendio();
        fetchAppsCliente();
    }

    useEffect(() => {
        fetchAllData();
        setLoading(false);
    }, []);

    async function handleSubmit(event) {

        event.preventDefault();
        const formData = new FormData(event.target);

        const objectFormData = Object.fromEntries(formData);

        objectFormData['email'] = '';
        objectFormData['appsCliente'] = appChecked;

        const listadoCamposSinDiligenciar = [];
        Object.keys(dataFormulario).forEach(
            key => {
                if (dataFormulario[key] === "" || dataFormulario[key] === -1) {
                    listadoCamposSinDiligenciar.push(listadoIdLabelObligatorios[key]);
                }
            }
        )

        if (appChecked.length === 0) {
            listadoCamposSinDiligenciar.push("Apps del cliente");
        }

        if (listadoCamposSinDiligenciar.length !== 0) {
            const cadenaCamposSinDiligenciar = listadoCamposSinDiligenciar.join(",");
            alert("Los datos siguientes no se han diligenciado " + cadenaCamposSinDiligenciar);

        } else {

            const resumenDeVenta = crearResumenVenta();
            console.log("Resumen de venta:", resumenDeVenta);

            await Swal.fire(
                {
                    title: "Resumen de venta",
                    html: resumenDeVenta,
                    showCancelButton: true,
                    confirmButtonText: "Guardar Venta",
                }
            ).then(
                async (result) => {
                    if (result.isConfirmed) {
                        await ApiService.create(ApiEndpoints.venta, objectFormData)
                            .then(
                                response => {
                                    Swal
                                        .fire('Venta Guardada', 'Tus datos han sido guardados', 'success')
                                        .then(
                                            resp => {

                                                setUpdatedVenta(updatedVenta + 1) /*   window.location.href = process.env.REACT_APP_DOMINIO_FRONT+ "/isasys/dashboard"*/
                                            }
                                        )
                                        ;
                                }
                            )
                            .catch(
                                error => {
                                    console.log(error)
                                    Swal.fire('Error', 'Se ha presentado un error al guardar los datos ' + error, 'error')
                                }
                            );
                    }
                }
            );
        }
    }

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            // Add the value to the array of checked values
            setAppChecked(prevCheckedValues => [...prevCheckedValues, value]);
        } else {
            // Remove the value from the array of checked values
            setAppChecked(prevCheckedValues => prevCheckedValues.filter(item => item !== value));
        }

    };

    const handleChangeInputMandatory = (event) => {
        const { name, value } = event.target;
        setDataFormulario(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const crearResumenVenta = () => {
        const resumenVenta = Object.entries(dataFormulario).map(
            ([key, value]) => {
                if (key === 'groupAppsCliente') {
                    return listadoLabelsTodosLosCampos[key] + ":" + appChecked.join(",");
                } else {
                    return listadoLabelsTodosLosCampos[key] + ":" + value;
                }
            }
        )
        return resumenVenta.join("</br>");
    }

    const today = new Date().toISOString().split("T")[0];

    return (
        <div>
            <Loading loading={loading} />
            <form onSubmit={handleSubmit}>

                <ContainerInput>
                    <div >
                        <FormLabel required>Fecha de la Venta</FormLabel>
                    </div>
                    <div >
                        <Input 
                        required 
                        type='date' 
                        name='fechaVenta' 
                        onChange={handleChangeInputMandatory}
                        inputProps={{max:today}}
                        ></Input>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>Fecha de vencimiento del servicio</FormLabel>
                    </div>
                    <div >
                        <Input 
                        required 
                        type='date' 
                        name='fechaVencimientoServicio' 
                        onChange={handleChangeInputMandatory}
                        inputProps={{min:today}}
                        ></Input>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>Nombre y Apellido del Cliente</FormLabel>
                    </div>
                    <div >
                        <Input type='text' name='nombreApellidoDelCliente' onChange={handleChangeInputMandatory}></Input>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>Usuario asignado al cliente</FormLabel>
                    </div>
                    <div >
                        <Input type='text' name='usuarioClienteIPTV' onChange={handleChangeInputMandatory}></Input>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel>Correo del Cliente</FormLabel>
                    </div>
                    <div >
                        <Input type='email' name='emailCliente'></Input>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel>Número de Celular del Cliente</FormLabel>
                    </div>
                    <div >
                        <Input type='text' name='celularCliente'></Input>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel>Número de Pedido</FormLabel>
                    </div>
                    <div >
                        <Input type='text' name='numeroDePedido'></Input>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>Cantidad de Meses Vendidos</FormLabel>
                    </div>
                    <div className='col-4'>
                        <select name="mesesVendidos" id="" className='form-select' onChange={handleChangeInputMandatory}>
                            <option key='-1' value="-1">Ninguno</option>
                            {cantidadMeses.map(cantidad => (
                                <option key={cantidad.cantidad} value={cantidad.cantidad}>{cantidad.cantidad}</option>
                            ))}
                        </select>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>Valor Pagado Por El Cliente</FormLabel>
                    </div>
                    <div className='col-4'>
                        <select name="valorPagado" id="" className='form-select' onChange={handleChangeInputMandatory}>
                            <option key='-1' value="-1">Ninguno</option>
                            {valorPagado.map(valor => (
                                <option key={valor.valor} value={valor.valor}>{valor.valor}</option>
                            ))}
                        </select>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>Moneda de Pago del Cliente</FormLabel>
                    </div>
                    <div className='col-4'>
                        <select name="monedaPago" id="" className='form-select' onChange={handleChangeInputMandatory}>
                            <option key='-1' value="-1">Ninguno</option>
                            {monedaPago.map(moneda => (
                                <option key={moneda.moneda} value={moneda.moneda}>{moneda.moneda}</option>
                            ))}
                        </select>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>País del Cliente</FormLabel>
                    </div>
                    <div className='col-4'>
                        <select name="pais" className='form-select' onChange={handleChangeInputMandatory}>
                            <option key='-1' value="-1">Ninguno</option>
                            {paises ? paises.map(pais => (
                                <option key={pais.pais} value={pais.pais}>{pais.pais}</option>
                            )) : ''}
                        </select>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>Por donde Pagó</FormLabel>
                    </div>
                    <div className='col-4'>
                        <select name="porDondePago" id="" className='form-select' onChange={handleChangeInputMandatory}>
                            <option key='-1' value="-1">Ninguno</option>
                            {porDondePago ? porDondePago.map(donde => (
                                <option key={donde.pordondepago} value={donde.pordondepago}>{donde.pordondepago}</option>
                            )) : ''}
                        </select>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>Tipo de Venta</FormLabel>
                    </div>
                    <div className='col-4'>
                        <select name="tipoVenta" id="" className='form-select' onChange={handleChangeInputMandatory}>
                            <option key='-1' value="-1">Ninguno</option>
                            {tipoVenta.map(tipo => (
                                <option key={tipo.tipo} value={tipo.tipo}>{tipo.tipo}</option>
                            ))}
                        </select>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>Cantidad de SmartOne IPTV Activados</FormLabel>
                    </div>
                    <div className='col-4'>
                        <select name="cantidadSmart" id="" className='form-select' onChange={handleChangeInputMandatory}>
                            <option key='-1' value="-1">Seleccionar</option>
                            {cantidadSmartOne.map(cantidad => (
                                <option key={cantidad.cantidad} value={cantidad.cantidad}>{cantidad.cantidad}</option>
                            ))}
                        </select>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>Pagina Que Vendió</FormLabel>
                    </div>
                    <div className='col-4'>
                        <select name="paginaQueVendio" id="" className='form-select' onChange={handleChangeInputMandatory}>
                            <option key='-1' value="-1">Ninguno</option>
                            {paginaQueVendio.map(pagina => (
                                <option key={pagina.pagina} value={pagina.pagina}>{pagina.pagina}</option>
                            ))}
                        </select>
                    </div>
                </ContainerInput>

                <ContainerInput>
                    <div >
                        <FormLabel required>Apps del Cliente</FormLabel>
                    </div>
                    <div id='groupAppsCliente'>
                        {
                            appsCliente.map(
                                app => (
                                    <div key={app.idappcliente} className='col-4 form-check'>
                                        <input className="form-check-input" type="checkbox" value={app.app} id="flexCheckDefault" onChange={handleCheckboxChange} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            {app.app}
                                        </label>
                                    </div>
                                )
                            )
                        }
                    </div>

                </ContainerInput>

                <ContainerInput>

                    <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary mb-3" >Guardar Venta</button>
                    </div>
                </ContainerInput>
            </form>




        </div>
    )
}

export default FormVentas