import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/authContext/authContext";
import ShowedContext from "../../context/showedContext/showedContext";
import axios from "axios";
import NavbarManager from "../../components/navbarManager/NavbarManager";
import NavbarVendedor from "../../components/navbarVendedor/NavbarVendedor";
import FormVentasVendedorPage from "../formVentasVendedor/FormVentasVendedorPage";
import TableVentasVendedorPage from "../tableVentasVendedor/TableVentasVendedorPage";
import TableVentasManagerPage from "../tableVentasManager/TableVentasManagerPage";
import TablaVentasSubManager from "../../components/tablaVentasSubManager/TablaVentasSubManager";
import TableReportesVentasSemanalPage from "../tableReportesVentasSemanal/TableReportesVentasSemanalPage";
import apiService from "../../services/apiService";
import apiEndpoints from "../../services/apiEndpoints";
import TablaUsuarios from "../../components/tablaUsuarios/TablaUsuarios";
import TableReportesVentasSemanalVendedorPage from "../tablaReportesVentasVendedor/TableReportesVentasSemanalVendedorPage";
import Swal from "sweetalert2";
import TablaPrecioCredito from "../../components/tablaPrecioCredito/TablaPrecioCredito";
import TablaPrecioPublicidad from "../../tablaPrecioPublicidad/TablaPrecioPublicidad";
import Loading from "../../components/loading/Loading";
import TableVentasSubManagerPage from "../tablaVentasSubManager/TableVentasSubManagerPage";
import TablaUsuariosSubManager from "../../components/tablaUsuariosSubManager/TablaUsuariosSubManager";
import TablaPrecioPublicidadSubManager from "../../tablaPrecioPublicidad SubManager/TablaPrecioPublicidadSubManager";

const Dashboard = () => {
    const { user, setUser } = useContext(AuthContext);
    const { showed } = useContext(ShowedContext);

    const [loading, setLoading] = useState(true);

    const auth = async () => {

        const response = await apiService.get(
            apiEndpoints.isLoggedIn,
            { withCredentials: true }
        );

        if (response.data === true) {


            const dataUser = await apiService.get(
                apiEndpoints.userLoggedData,
                { withCredentials: true }
            )

            console.log(dataUser);


            if (dataUser.data.error) {
                console.log("Ingrese aca")
                Swal.fire(
                    {
                        title: "Error",
                        text: dataUser.data.error,
                        icon: "error"
                    }
                ).then(
                    () => {
                        window.location.href = "http://localhost:3000/isasys/login"
                    }
                )
            } else {
                setUser(
                    dataUser.data
                    /*{
                        email: 'isaac@correo.com',
                        roles: ['manager'],
                        nombres: 'Isaac'
                    }*/
                );
            }




        }
        setLoading(false);

    }

    useEffect(() => {
        auth();
    }, [])

    return (
        <div className='container'>
            <Loading loading={loading} />

            {!user.email ? '' : (user.roles.includes('manager') || user.roles.includes('submanager') ? <NavbarManager /> : <NavbarVendedor />)}

            {user.email && (
                <>
                    <div className={showed === 'ingresarventas' ? 'visible' : 'hidden'}>
                        <FormVentasVendedorPage />
                    </div>
                    <div className={showed === 'misventas' ? 'visible' : 'hidden'}>
                        <TableVentasVendedorPage />
                    </div>
                    <div className={showed === 'mireporte' ? 'visible' : 'hidden'}>
                        <TableReportesVentasSemanalVendedorPage />
                    </div>


                    {user.roles.includes('manager') ?
                        <>
                            <div className={showed === 'ventas' ? 'visible' : 'hidden'}>
                                <TableVentasManagerPage />
                            </div>
                            <div className={showed === 'ventasSemanal' ? 'visible' : 'hidden'}>
                                <   TableReportesVentasSemanalPage />
                            </div>
                            <div className={showed === 'usuarios' ? 'visible' : 'hidden'}>
                                <TablaUsuarios />
                            </div>
                            <div className={showed === 'precioCredito' ? 'visible' : 'hidden'}>
                                <TablaPrecioCredito />
                            </div>
                            <div className={showed === 'precioPublicidad' ? 'visible' : 'hidden'}>
                                <TablaPrecioPublicidad />
                            </div>
                        </>
                        : ''}

                    {
                        user.roles.includes('submanager') ?
                            <>
                                <div className={showed === 'ventas' ? 'visible' : 'hidden'}>
                                    <TableVentasSubManagerPage />
                                </div>
                                <div className={showed === 'ventasSemanal' ? 'visible' : 'hidden'}>
                                    <   TableReportesVentasSemanalPage />
                                </div>
                                <div className={showed === 'usuarios' ? 'visible' : 'hidden'}>
                                    <TablaUsuariosSubManager />
                                </div>
                                <div className={showed === 'precioCredito' ? 'visible' : 'hidden'}>
                                    <TablaPrecioCredito />
                                </div>
                                <div className={showed === 'precioPublicidad' ? 'visible' : 'hidden'}>
                                    <TablaPrecioPublicidadSubManager />
                                </div>
                            </>
                            : ''
                    }
                </>
            )}
        </div>
    )

}

export default Dashboard;