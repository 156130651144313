import * as React from 'react';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import ApiService from '../../services/apiService';
import { listadoLabelsVentaDTOManager } from '../formularioVentas/Validaciones';
import Swal from 'sweetalert2';

import './TablaVentasSubManager.css'
import Loading from "../loading/Loading";


export default function TablaVentasSubManager() {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20
    });
    const [sortModel, setSortModel] = React.useState([]);
    const [filterModel, setFilterModel] = React.useState({
        items: []
    });

    const [totalRows, setTotalRows] = React.useState(0);

    const [loading, setLoading] = React.useState(false);

    const apiRef = useGridApiRef();

    const handleFilterModelChange = (newFilterModel) => {
        
        // Update the input filter value as the user types
        if (newFilterModel.items.length > 0) {
            console.log(newFilterModel)
                setFilterModel(newFilterModel);
        }
    };

    const handleDelete = async () => {
        const filasSeleccionadas = apiRef.current.getSelectedRows();
        let tempRows = [...rows];
        const response = await Swal.fire(
            {
                title: "Advertencia",
                text: "¿Estás seguro de eliminar las filas seleccionadas?. Los datos no se podrán recuperar",
                icon: "error",
                showCancelButton: true
            }
        ).then(() => {
            for (let [key] of filasSeleccionadas) {
                try {
                    ApiService.delete("/ventas/" + key);
                    tempRows = tempRows.filter(row => row.id !== key);
                } catch (error) {
                    console.error('Error deleting row:', error);
                    return tempRows;
                }
            }
            return tempRows;
        });

        setRows(response);
    }

    const actualizarDatos = async (updatedRow, originalRow) => {
        const ventaNormalizada = normalizarJsonVenta(updatedRow);
        const response = await ApiService.update("/ventas/" + ventaNormalizada.id, ventaNormalizada);

        if (response.error) {
            Swal.fire(
                {
                    title: "Error de validación de datos",
                    html: response.error,
                });

            return originalRow;
        }
        return updatedRow;
    }

    const normalizarJsonVenta = (filaTabla) => {
        const keyToRemove = 'marcaTemporal';
        const { [keyToRemove]: _, ...newObject } = filaTabla;
        return newObject;
    }

    const fetchVentas = async () => {
        setLoading(true);

        // Extract sorting parameters
        const sortBy = sortModel.length > 0 ? sortModel[0].field : null;
        const sortDirection = sortModel.length > 0 ? sortModel[0].sort : null;

        // Extract filtering parameters
        const filterField = filterModel.items.length > 0 ? filterModel.items[0].field : null;
        const filterValue = filterModel.items.length > 0 ? filterModel.items[0].value : null;

        // Fetch data with sorting, filtering, and pagination
        const response = await ApiService.getAllPageSize(
            "/ventas",
            paginationModel.page,
            paginationModel.pageSize,
            sortBy,
            sortDirection,
            filterField,
            filterValue
        );

        const columnas = Object.entries(listadoLabelsVentaDTOManager).map(([field, headerName]) => (
            {
                field,
                headerName,
                width: 150
            }
        ));

        setColumns(columnas);
        setLoading(false);

        const filas = response.data.content;
        setRows(filas);
        setTotalRows(response.data.totalElements);
    }

    React.useEffect(
        () => {
            fetchVentas();
        }
        , [paginationModel, sortModel, filterModel])


    return (

        <div className='contenedor-tabla-ventas-manager' style={{ height: 750, width: '100%' }}>

            {
                <>
                    <Loading 
                    loading={loading}
                    />
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        paginationMode="server"
                        paginationModel={paginationModel}
                        rowCount={totalRows}
                        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
                        pageSizeOptions={[20, 40, 100]}
                        apiRef={apiRef}
                        disableRowSelectionOnClick
                        density="compact"
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => {
                            setSortModel(newSortModel);
                            fetchVentas();
                        }}
                        filterMode="server"
                        onFilterModelChange={(newFilterModel) => {
                            handleFilterModelChange(newFilterModel);
                        }}
                        processRowUpdate={(updatedRow, originalRow) => actualizarDatos(updatedRow, originalRow)}
                        onProcessRowUpdateError={(error) => console.log(error)}
                        checkboxSelection
                    />
                </>
            }


            <div className='d-flex justify-content-end'>
                <button className='btn btn-danger mt-1' onClick={handleDelete}>Borrar</button>
            </div>


        </div>

    );
}