import React from 'react'
import TablaVentasManager from '../../components/tablaVentasManager/TablaVentasManager'

const TableVentasManagerPage = ({user}) => {
  return (
    <>
        
            <TablaVentasManager userData={user}/>
        
    </>
  )
}

export default TableVentasManagerPage