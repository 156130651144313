import {useContext, useEffect, useState} from "react";
import {objetoVendedorPagina, initializeShowsVendedorPagina, initializeSelectsVendedorPagina} from "./Utils";
import FilterSelectMenuVendedor from "./filterselectmenu/FilterSelectMenuVendedor";
import FilterSelectMenuPagina from "./filterselectmenu/FilterSelectMenuPagina";
import './FiltroVendedorPagina.css'
import DataMostrarContext from "../context/DataMostrarContext";
import {aplicarFiltros, fVendedorPagina} from "../../utils/Filtros";
import InitialSelectsContext from "../context/InitialSelectsContext";
import {InitialSelectsVendedorPaginaContext} from "../context/InitialSelectsVendedorPaginaContext";
import DataContext from "../context/DataContext";

const FiltroVendedorPagina = () => {

    const {data, setData} = useContext(DataContext);
    const {dataMostrar, setDataMostrar} = useContext(DataMostrarContext);
    const {initialSelecteds, setInitialSelecteds} = useContext(InitialSelectsContext)
    const {
        initialSelectsVendedorPagina,
        setInitialSelectsVendedorPagina
    } = useContext(InitialSelectsVendedorPaginaContext)

    const [objectVendedorPagina, setObjectVendedorPagina] = useState();
    //const [stateObjectoVendedorPagina, setStateObjetoVendedorPagina] = useState(objetoVendedorPagina(data));
    const [initialShows, setInitialShows] = useState();


    const handleShowPaginas = (index, initialShows) => {
        const prevInitialShows = [...initialShows];
        prevInitialShows[index]["showPaginas"] = !prevInitialShows[index]["showPaginas"] === true;
        setInitialShows(prevInitialShows);
    }

    const handleCheckSeleccionarTodo = (event) => {
        const checked = event.target.checked;
        const prevInitialSelectedsVendedorPagina = [...initialSelectsVendedorPagina];
        prevInitialSelectedsVendedorPagina.forEach(
            (usuarioObj) => {
                usuarioObj['isUsuarioSelected'] = checked;
                usuarioObj['paginas'].forEach(
                    (pagina) => {
                        pagina['isPaginaSelected'] = checked;
                    }
                )
            }
        )
        setInitialSelectsVendedorPagina(prevInitialSelectedsVendedorPagina);
        setDataMostrar(aplicarFiltros(data, initialSelecteds, initialSelectsVendedorPagina))
    }

    const handleCheckChangeVendedores = (index, initialSelectsVendedorPagina) => {
        const previInitialSelecteds = [...initialSelectsVendedorPagina];
        previInitialSelecteds[index]["isUsuarioSelected"] = !previInitialSelecteds[index]["isUsuarioSelected"] === true;

        previInitialSelecteds[index]["paginas"].forEach(
            (pagina) => {
                pagina["isPaginaSelected"] = previInitialSelecteds[index]["isUsuarioSelected"]
            }
        )
        setInitialSelectsVendedorPagina(previInitialSelecteds);
        setDataMostrar(aplicarFiltros(data, initialSelecteds, initialSelectsVendedorPagina))
    }

    const handleCheckChangePaginas = (index, indexMonth, initialSelectsVendedorPagina) => {
        const previInitialSelecteds = [...initialSelectsVendedorPagina];
        previInitialSelecteds[index]["paginas"][indexMonth]["isPaginaSelected"] = !previInitialSelecteds[index]["paginas"][indexMonth]["isPaginaSelected"] === true;

        setInitialSelectsVendedorPagina(previInitialSelecteds);
        setDataMostrar(aplicarFiltros(data, initialSelecteds, initialSelectsVendedorPagina))
    }

    useEffect(() => {

        setObjectVendedorPagina(
            (prevState) => {
                const newState = objetoVendedorPagina(data);
                setInitialShows(initializeShowsVendedorPagina(newState));
                setInitialSelectsVendedorPagina(initializeSelectsVendedorPagina(newState));
                return newState;
            }
        );
    }, [data]);


        return (


        <div className="containerFilterVendedorPagina">
            <input
                type="checkbox"
                name="select-all-semanas"
                className="form-check-input"
                onChange={handleCheckSeleccionarTodo}
            />
            <label htmlFor="select-all-semanas">Seleccionar todo</label>
            {initialShows && initialSelectsVendedorPagina.length > 0 ? Object.entries(objectVendedorPagina).map(
                ([idUsuario, objData], index) => {
                    return (<>
                        <FilterSelectMenuVendedor

                            handleShowPaginas={() => {
                                handleShowPaginas(index, initialShows)
                            }}
                            handleCheckChangeVendedores={() => {
                                handleCheckChangeVendedores(index, initialSelectsVendedorPagina)
                            }

                            }
                            index={index}
                            ano={objData["nombreCompleto"]}
                            initialShows={initialShows}
                            initialSelecteds={initialSelectsVendedorPagina}
                        />
                        <div className="containerPaginas">
                            {initialShows ?
                                initialShows[index]["showPaginas"] && (
                                    objData["paginas"].map(
                                        (pagina, indexPagina) => {
                                            return (<>
                                                    <FilterSelectMenuPagina

                                                        handleCheckChangePaginas={() => {
                                                            handleCheckChangePaginas(index, indexPagina, initialSelectsVendedorPagina);
                                                        }
                                                        }
                                                        index={index}
                                                        indexMonth={indexPagina}
                                                        pagina={pagina}
                                                        initialSelecteds={initialSelectsVendedorPagina}
                                                    />

                                                </>
                                            )
                                        }
                                    )
                                ) : ''
                            }
                        </div>
                    </>)
                }
            ) : ''}

        </div>
    );

}

export default FiltroVendedorPagina;