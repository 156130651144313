import { useState } from "react";
import FechaInicialFinalContext from "./FechaInicialFinalContext";

const FechaInicialFinalContextProvider = ({ children }) => {
    
    const today = new Date();
    const twoMonthsAgo = new Date(today.setMonth(today.getMonth() - 3));
    const fechaInicial = twoMonthsAgo.toISOString().split('T')[0];

    const [fechas, setFechas] = useState(
        {
            fechaInicial: fechaInicial,
            fechaFinal: new Date().toISOString().split('T')[0]
        }
    );

    return (
        <FechaInicialFinalContext.Provider value={{ fechas, setFechas }}>
            {children}
        </FechaInicialFinalContext.Provider>
    )
}

export default FechaInicialFinalContextProvider;