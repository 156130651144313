import './Totales.css'
import {useContext} from "react";
import DataMostrarContext from "../context/DataMostrarContext";
import {
    calculateAccumulatedByMonth,
    calculateTotalGananciaFinalVendedor, calculateTotalGananciaNetaManager,
    calculateTotalPublicidad
} from "../../utils/TotalesLodash";


const Totales = () => {

    const {dataMostrar, setDataMostrar} = useContext(DataMostrarContext);

    const acumuladoTotalGananciaFinalVendedor = calculateTotalGananciaFinalVendedor(dataMostrar);
    const acumuladoTotalPublicidad = calculateTotalPublicidad(dataMostrar);
    const gananciaNetaFinalManager = calculateTotalGananciaNetaManager(dataMostrar);

    return (
        <>

            {dataMostrar ?
                <div className="contenedorTotales">
                    <div style={{
                        textAlign: "center"
                    }}>
                        <h3>
                            Ganancia Neta Final Vendedor
                        </h3>
                        <div>Se ha descontado publicidad</div>
                        <h4 className={acumuladoTotalGananciaFinalVendedor > 0 ? "positiva" : "negativa"}>
                            {acumuladoTotalGananciaFinalVendedor}
                        </h4>
                    </div>

                    <div style={{
                        textAlign: "center"
                    }}>

                        <h3>
                            Ganancia Neta Final Manager
                        </h3>
                        <div>NO se ha descontado publicidad</div>
                        <h4 className={gananciaNetaFinalManager > 0 ? "positiva" : "negativa"}>
                            {gananciaNetaFinalManager}
                        </h4>

                    </div>

                    <div style={{
                        textAlign: "center"
                    }}>

                        <h3>
                            Gasto Total Publicidad
                        </h3>
                        <h4>
                            {acumuladoTotalPublicidad}
                        </h4>

                    </div>
                </div> : ''}

        </>
    );
}

export default Totales;

