import axios from "axios";
import ApiEndpoints from "../../../../../../services/apiEndpoints";
import ApiService from "../../../../../../services/apiService";
import {useContext, useEffect, useState} from "react";
import FiltroSemanas from "../filtrosemanas/FiltroSemanas";
import './ReporteVentasSemanal.css'
import DataMostrarContext from "../context/DataMostrarContext";
import {
    calcularDescuentoCripto,
    calculateAccumulatedBySeller,
    calculateAccumulatedBySellerAndPage,
    processData
} from "../../utils/TotalesLodash";
import DataContext from "../context/DataContext";
import FiltroVendedorPagina from "../filtrovendedorpagina/FiltroVendedorPagina";
import {initializeSelectsVendedorPagina, objetoVendedorPagina} from "../filtrovendedorpagina/Utils";
import InitialSelectsContext from "../context/InitialSelectsContext";
import {InitialSelectsVendedorPaginaContext} from "../context/InitialSelectsVendedorPaginaContext";
import FiltroSemanasImplementation from "../filtrosemanas/FiltroSemanasImplementation";
import FiltroTipoVenta from "../filtrotipoventa/FiltroTipoVenta";
import BarChart from "../barchart/BarChart";
import Totales from "../totales/Totales";
import AuthContext from "../../../../../../context/authContext/authContext";
import UpdatedVentaContext from "../../../../../../context/updatedVentaContext/updatedVentaContext";
import FechaInicialFinalContext from "../context/FechaInicialFinalContext";

const filtroSemanasImpl = new FiltroSemanasImplementation();

const indexesSubTotals = (data, columnaIndice, columnaSemana) => {
    const indices = [];
    data.forEach(
        (value, index) => {
            if (index > 0) {
                if (value[columnaIndice] !== data[index - 1][columnaIndice] || value[columnaSemana] !== data[index - 1][columnaSemana]) {
                    indices.push(index - 1);
                }
            }
        }
    )
    indices.push(data.length - 1);
    return indices;
}

const indexTotalsSellerPage = (data, columnaUsuario, columnaPage) => {

    const indices = [];
    data.forEach(
        (value, index) => {
            if (index > 0) {
                if (value[columnaUsuario] !== data[index - 1][columnaUsuario] || value[columnaPage] !== data[index - 1][columnaPage]) {
                    indices.push(index - 1);
                }
            }
        }
    )
    indices.push(data.length - 1);
    return indices;

}

const indexesTotalsSeller = (data, columnaUsuario) => {
    const indices = [];
    data.forEach(
        (value, index) => {
            if (index > 0) {
                if (value[columnaUsuario] !== data[index - 1][columnaUsuario]) {
                    indices.push(index - 1);
                }
            }
        }
    )
    indices.push(data.length - 1);
    return indices;
}

const ReporteVentasSemanal = () => {

    const{user} = useContext(AuthContext);
    const{updatedVenta, setUpdatedVenta} = useContext(UpdatedVentaContext);

    const { fechas, setFechas } = useContext(FechaInicialFinalContext);

    const {data, setData} = useContext(DataContext);
    const {dataMostrar, setDataMostrar} = useContext(DataMostrarContext);
    const {initialSelecteds, setInitialSelecteds} = useContext(InitialSelectsContext);
    const {
        initialSelectsVendedorPagina,
        setInitialSelectsVendedorPagina
    } = useContext(InitialSelectsVendedorPaginaContext)

    const [displayFiltroSemanas, setDisplayFiltroSemanas] = useState('none');
    const [hideFiltroSemanas, setHideFiltroSemanas] = useState(false);
    const [hideFiltroPaginaVendedor, setHideFiltroPaginaVendedor] = useState(false);
    const [hideFiltroTipoVenta, setHideFiltroTipoVenta] = useState(false);

    const [indicesTotalesSellerPage, setIndicesTotalesSellerPage] = useState([]);
    const [indicesTotalesSeller, setIndicesTotalesSeller] = useState([]);

    const [totalesSellerPage, setTotalesSellerPage] = useState([]);
    const [totalesSeller, setTotalesSeller] = useState([]);

    const getData = async () => {
        const userinformation = await ApiService.get(ApiEndpoints.userLoggedData);
        const idUsuario = userinformation.data.idusuario;

        const response = await ApiService.get(ApiEndpoints.reportes.ventasSemanalVendedor, {
            idUsuario:idUsuario,
            idsTipoVenta: "1,2",
            dateInicial:fechas['fechaInicial'],
            dateFinal:fechas['fechaFinal']
        })
        setData(response.data);
        setDataMostrar(response.data);
    }

    const handleFechaInicial = (event)=>{
        const fechasUpdate = {...fechas};
        fechasUpdate['fechaInicial']=event.target.value;
        setFechas(fechasUpdate);
    }

    const handleFechaFinal = (event)=>{
        const fechasUpdate = {...fechas};
        fechasUpdate['fechaFinal']=event.target.value;
        setFechas(fechasUpdate);
    }

    useEffect(() => {
        getData()
        console.log("actualizar en reporte")
    }, [fechas, updatedVenta]);

    useEffect(() => {
        //if (initialSelecteds.length === 0) {
            const objectYears = filtroSemanasImpl.createObjectInitializeSelectsSemanas(data);
            setInitialSelecteds(filtroSemanasImpl.initializeSelectsSemanas(objectYears));
        //}
        const objectVendedorPagina = objetoVendedorPagina(data)
        setInitialSelectsVendedorPagina(initializeSelectsVendedorPagina(objectVendedorPagina));
        console.log("La data ha cambiado")
    }, [data]);

    useEffect(() => {
        if (dataMostrar.length > 0) {
            setTotalesSellerPage(calcularDescuentoCripto(calculateAccumulatedBySellerAndPage(dataMostrar), 0.02));
            setIndicesTotalesSellerPage(indexTotalsSellerPage(dataMostrar, "idUsuario", "paginaVendida"))

            setTotalesSeller(calcularDescuentoCripto(calculateAccumulatedBySeller(dataMostrar), 0.02));
            setIndicesTotalesSeller(indexesTotalsSeller(dataMostrar, "idUsuario"));
        }
        console.log("La datamostrar cambió")
    }, [dataMostrar]);

    return (

        <div>
            <div className="contenedor-rango-fechas container m-auto">
                <div className="row justify-content-center">
                    <div className="col-3">
                        <label for="fecha-inicio" className="form-label mt-2 mb-1" >Fecha inicial</label>
                        <input onChange={(event)=> handleFechaInicial(event) } id="fecha-inicio" className="form-control" type="date" value={fechas['fechaInicial']} />
                    </div>

                    <div className="col-3">
                        <label for="fecha-final" className="form-label mt-2 mb-1">Fecha final</label>
                        <input onChange={(event)=> handleFechaFinal(event)} id="fecha-final" className="form-control" type="date" value={fechas['fechaFinal']} />
                    </div>
                </div>
            </div>

            {data.length > 0 ? (<>
                    <div className="contenedorMenuFiltros">
                        <div style={{position: "relative"}}>
                            <>
                                <button
                                className="btn btn-primary"
                                    onClick={
                                        () => {
                                            if (hideFiltroTipoVenta) {
                                                setHideFiltroTipoVenta(false);
                                            } else {
                                                setHideFiltroTipoVenta(true);
                                            }
                                        }
                                    }
                                >Tipo de Venta<i class="icon-filter"></i>
                                </button>
                                <div className={hideFiltroTipoVenta ? '' : 'hidden'}>
                                    <FiltroTipoVenta/>
                                </div>
                            </>
                        </div>
                        <div style={{position: "relative"}}>
                            <>
                                <button
                                className="btn btn-primary"
                                    onClick={
                                        () => {
                                            if (hideFiltroSemanas) {
                                                setHideFiltroSemanas(false);
                                            } else {
                                                setHideFiltroSemanas(true);
                                            }
                                        }
                                    }
                                >Fechas<i class="icon-filter"></i>
                                </button>
                                <div className={hideFiltroSemanas ? '' : 'hidden'}>
                                    <FiltroSemanas/>
                                </div>
                            </>
                        </div>
                        <div style={{position: "relative"}}>
                            <>
                                <button
                                className="btn btn-primary"
                                    onClick={
                                        () => {
                                            if (hideFiltroPaginaVendedor) {
                                                setHideFiltroPaginaVendedor(false);
                                            } else {
                                                setHideFiltroPaginaVendedor(true);
                                            }
                                        }
                                    }
                                >Vendedor<i class="icon-filter"></i>
                                </button>
                                <div className={hideFiltroPaginaVendedor ? '' : 'hidden'}>
                                    <FiltroVendedorPagina/>
                                </div>
                            </>
                        </div>
                    </div>
                    <div style={{
                        height: "60vh",
                        display: "flex",
                        justifyContent: "center",
                        gap: "50px",
                        margin: "10px"
                    }}>
                        <BarChart
                            dataMostrar={dataMostrar}
                            campo={"gananciaFinalVendedor"}
                        />
                        <Totales/>
                    </div>

                    <div className="contenedorTablaReporteVentasSemanal">

                        <table className="tablaAcumuladoVentasSemanal">
                            <thead>
                            <tr key="encabezados">
                                {
                                    Object.keys(data[0]).map((enc, indexEnc) => {
                                        return <th key={indexEnc} className="encabezado-reporte-ventas-semanal">
                                            <div>

                                                {enc}{
                                                enc === "semana" ? '' : ''
                                            }
                                            </div>
                                        </th>

                                    })
                                }
                            </tr>
                            </thead>

                            <tbody>
                            {
                                dataMostrar.map(
                                    (row, indexRow) => {
                                        return (
                                            <>
                                                <tr key={indexRow}>
                                                    {Object.entries(row).map(([enc, valor], indexCelda) => {
                                                        return (
                                                            <td 
                                                            className={parseFloat(valor)<0?"negativo":""} 
                                                            key={indexCelda}
                                                            >
                                                                {indexCelda === 0 ? valor.split(" ")[1] : valor}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                                {
                                                    indicesTotalesSellerPage.includes(indexRow)
                                                        ? (

                                                            <tr className="subTotalSellerPage" key={indexRow + '_subtotal_sellerpage'}>
                                                                <th>SubTotal Vendedor-Página</th>
                                                                {Object.entries(totalesSellerPage[indicesTotalesSellerPage.indexOf(indexRow)]).map(([enc, valor], indexCelda) => {
                                                                    return (
                                                                        <>

                                                                            <th className={parseFloat(valor)<0?"negativo":""} key={indexCelda}>
                                                                                {valor}
                                                                            </th>
                                                                            {
                                                                                indexCelda === 3 ?
                                                                                    <>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                    </>
                                                                                    : ''
                                                                            }

                                                                        </>
                                                                    )
                                                                })}
                                                            </tr>
                                                        ) : ''
                                                }
                                                {
                                                    indicesTotalesSeller.includes(indexRow)
                                                        ? (

                                                            <tr className="subTotalSeller" key={indexRow + '_subtotal_seller'}>
                                                                <th>Total Vendedor</th>
                                                                {Object.entries(totalesSeller[indicesTotalesSeller.indexOf(indexRow)]).map(([enc, valor], indexCelda) => {
                                                                    return (
                                                                        <>

                                                                            <th className={parseFloat(valor)<0?"negativo":""} key={indexCelda}>
                                                                                {valor}
                                                                            </th>
                                                                            {
                                                                                indexCelda === 2 ?
                                                                                    <>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                    </>
                                                                                    : ''
                                                                            }

                                                                        </>
                                                                    )
                                                                })}
                                                            </tr>
                                                        ) : ''
                                                }
                                            </>
                                        )
                                    }
                                )
                            }
                            </tbody>
                        </table>
                    </div>

                </>
            ) : ""}
        </div>
    );
}

export default ReporteVentasSemanal;