import { useEffect, useState } from "react";
import ApiService from "../../../services/apiService";
import ApiEndpoints from "../../../services/apiEndpoints";
import "./ModalAgregarPublicidad.css"


const ModalAgregarPublicidad = ({ show, setShowModalPrecioPublicidad }) => {

    const [vendedores, setVendedores] = useState([]);
    const [paginas, setPaginas] = useState([]);
    const [semanas, setSemanas] = useState([]);

    const [idUsuario, setIdUsuario] = useState(0);
    const [idPagina, setIdPagina] = useState(0);

    const [semanaSeleccionada, setSemanaSeleccionada] = useState("");
    const [objetoSemanaSeleccionada, setObjetoSemanaSeleccionada] = useState(
        {
            "inicioSemana":"",
            "finSemana":""
        }
    )

    const [porcentaje, setPorcentaje] = useState(0);
    const [precio, setPrecio] = useState(0);

    const fraseSemana =(semana) => {
      return semana.year + " No " + semana.numberWeek + " " + semana.beginWeekDate + " a " + semana.endWeekDate;
    }

    const fetchVendedores = async () => {

        try {
            const response = await ApiService.get(ApiEndpoints.usuario);
            setVendedores(response.data);
        } catch (error) {

        }

    }

    const fetchPaginas = async (idusuario) => {
        try {
            const response = await ApiService.get(ApiEndpoints.paginaQueVendioIdUsuario + "/" + idusuario)
            setPaginas(response.data);
        } catch (error) {

        }
    }

    const fetchSemanas = async (substring) => {
        try {
            const response = await ApiService.get(ApiEndpoints.semana + "/" + substring);
            setSemanas(response.data);
        } catch (error) {

        }
    }

    const handleChangeVendedor = (event) => {
        if (event.target.value !== "-1") {
            fetchPaginas(event.target.value);
            setIdUsuario(event.target.value);
        } else {
            setPaginas([]);
        }
    }

    const handleChangePagina = (event) => {
        console.log("cambio pagina "+event.target.value);
        
        if(event.target.value!==-1){
            setIdPagina(event.target.value);
        }
    }

    const handleChangeFiltroSemana = (event) => {
        fetchSemanas(event.target.value)
    }

    const handleSemanaSeleccionada = (semana)=>{
        setSemanaSeleccionada(fraseSemana(semana));
        const fechaInicialFinalSemana = {
            "inicioSemana":semana.beginWeekDate,
            "finSemana":semana.endWeekDate
        }
        setObjetoSemanaSeleccionada(fechaInicialFinalSemana);
    }

    const handleChangePorcentaje = (event)=>{
        setPorcentaje(event.target.value);
    }


    const handleChangePrecio = (event)=>{
        setPrecio(event.target.value);
    }

    const guardarPrecioPublicidad = async () => {
        try {
            const response = await ApiService.post(ApiEndpoints.precioPublicidad,{
                porcentaje:porcentaje,
                precio:precio,
                inicioSemana:objetoSemanaSeleccionada['inicioSemana'],
                finSemana:objetoSemanaSeleccionada['finSemana'],
                idUsuario:idUsuario,
                idPagina:idPagina
              })      
        } catch (error) {
            console.log("Error al guardar los datos del precio publicidad ");
            console.log(error);
            
        }
      
    }

    useEffect(
        () => {
            fetchVendedores();
        }, []
    )

    if (!show) {
        return null;
    }

    return (
        <>
            <div className="out-modal-precio-publicidad">
                <div className="contenedor-modal-precio-publicidad">
                    <h2>Agregar precio de publicidad</h2>
                    <div className="mt-1">
                        <label className="form-label" for="vendedor">Vendedor</label>
                        <select className="form-select" onChange={(event) => handleChangeVendedor(event)} name="vendedor" id="vendedor">
                            <option value={-1}>Vendedor</option>
                            {
                                vendedores.map(
                                    (vendedor) => {
                                        return <option value={vendedor.idusuario}>{vendedor.nombres + " " + vendedor.apellidos}</option>
                                    }
                                )
                            }
                        </select>
                    </div>

                    <div className="mt-3">
                        <label className="form-label" for="pagina">Página</label>
                        <select className="form-select" onChange={(event)=>handleChangePagina(event)}  name="pagina" id="pagina">
                            <option value={-1}>Página</option>
                            {paginas.map(
                                (pagina) => {
                                    return <option value={pagina.idpaginaquevendio}> {pagina.pagina} </option>
                                }
                            )}
                        </select>
                    </div>

                    <div className="mt-3">
                        <label className="form-label" for="semanas">Semana</label>
                        <button className="list-group-item active">{semanaSeleccionada}</button>
                        <input
                        className="form-control mt-2 mb-2"
                            onChange={(event) => handleChangeFiltroSemana(event)}

                            list="semanas"
                            type="text"
                            id="semana"
                            placeholder="AAAA-MM-DD"
                        />
                        <ul className="list-group">
                            {
                                semanas.map(
                                    (semana) => (
                                        <button className="list-group-item list-group-item-action"  onClick={ ()=>handleSemanaSeleccionada(semana) } >{fraseSemana(semana)}</button>
                                    )
                                )
                            }

                        </ul>
                    </div>

                    <div className="mt-2">
                        <label className="form-label">Porcentaje</label>
                        <input className="form-control" onChange={(event)=>handleChangePorcentaje(event)} type="number" />
                    </div>

                    <div className="mt-2">
                        <label className="form-label">Precio</label>
                        <input className="form-control" onChange={(event)=>handleChangePrecio(event)} type="number" />
                    </div>

                    <div className="mt-3 contenedor-botones-modal-precio-publicidad">
                        <button onClick={() => {
                          setShowModalPrecioPublicidad(false)
                        }} className="btn btn-danger">Cancelar</button>
                    <button className="btn btn-primary" onClick={()=>guardarPrecioPublicidad()} >Agregar</button>
                    </div>
                    
                </div>
            </div>

        </>
    )
}

export default ModalAgregarPublicidad;