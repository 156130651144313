
const objectForYear = (initialSelecteds, year)=>{
    return initialSelecteds.filter(
        (obj)=>{
            return parseInt(obj['indexYear'])===parseInt(year);
        }
    )[0]
}

const objectForMonth = (months, month)=>{
    return months.filter(
        (obj)=>{
            return parseInt(obj['month'])===parseInt(month);
        }
    )[0]
}

const objectForDay = (days, day)=>{
    return days.filter(
        (obj)=>{
            return obj['day']===day;
        }
    )[0]
}

export const objectForVendedor = (initialSelectsVendedorPagina, idUsuario)=>{

    return initialSelectsVendedorPagina.filter(
        (row)=>{
            return parseInt(row['indexUsuario'])===parseInt(idUsuario)
        }
    )[0]

}

export const objPaginaForVendedor = (objVendedor, pagina)=>{
    return objVendedor['paginas'].filter(
        (objPagina)=>{
            return objPagina['pagina']===pagina;
        }
    )[0]
}

export const fSemana = (data, initialSelecteds) => {
    const dataAMostrar = data.filter(
        (row) => {
            const year = row['semana'].split(" ")[3].split("-")[0];
            const month = row['semana'].split(" ")[3].split("-")[1];
            const semana = row['semana'];

            const objForYear = objectForYear(initialSelecteds,year);
            const yearSelected = objForYear['isYearSelected']

            const months = objForYear['months']
            const objForMonth = objectForMonth(months, month)
            const monthSelected = objForMonth['isMonthSelected']

            const days = objForMonth['days'];
            const objForDay = objectForDay(days, semana);
            const daySelected = objForDay['isDaySelected'];


            return daySelected;

            //return yearSelected || monthSelected || daySelected
        }
    )
    return dataAMostrar;
}

export const fVendedorPagina = (data, initialSelectedsVendedorPagina)=>{

    const dataAMostrar = data.filter(
        (row) => {
            const vendedor = row['idUsuario'];
            const pagina = row['paginaVendida'];
            const objVendedor = objectForVendedor(initialSelectedsVendedorPagina, vendedor);
            const isPaginaSelected = objPaginaForVendedor(objVendedor,pagina)['isPaginaSelected'];
            return isPaginaSelected;
        }
    )
    return dataAMostrar;

}

export const aplicarFiltros = (data, initialSelecteds, ininitialSelectedsVendedorPagina)=>{

    const filtroSemana = fSemana(data, initialSelecteds);
    const filtroVendedorPagina = fVendedorPagina(filtroSemana, ininitialSelectedsVendedorPagina);

    return filtroVendedorPagina;

}


