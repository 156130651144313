import DataContext from "./DataContext";
import {useState} from "react";

const DataContextProvider = ({children})=>{

    const [data, setData] = useState([]);

    return(
      <DataContext.Provider value={{data, setData}}>
          {children}
      </DataContext.Provider>
    );

}

export default DataContextProvider;