import "./TablaUsuariosSubManager.css";
import { DataGrid } from "@mui/x-data-grid";
import apiService from "../../services/apiService";
import apiEndpoints from "../../services/apiEndpoints";
import { useEffect, useState } from "react";
import { aplicarFiltros } from "../reportes/manager/contenedorreporteventassemanalmanager/utils/Filtros";
import ModalAgregarUsuario from "./components/modalagregarusuario/ModalAgregarUsuario";
import Swal from "sweetalert2";
import ModalEditarUsuario from "./components/modaleditarusuario/ModalEditarUsuario";

const TablaUsuariosSubManager = () => {
    const [rows, setRows] = useState([]);

    const fetchUsuarios = async () => {
        const response = await apiService.get(apiEndpoints.usuario);
        console.log(response.data);
        setRows(response.data);
    }

    const columns = [
        {
            field: "idusuario", width: 80
        },
        { field: "nombres", width: 100 },
        { field: "apellidos", width: 100 },
        { field: "email", width: 150 },
        { field: "roles", width: 150 },
        { field: "paginaquevendios", width: 350 },
        { field: "activo" }
    ]

    const getRowId = (row) => {
        return row.idusuario;
    }

    useEffect(() => {
        fetchUsuarios();
    }, []);

    return (
        <div className={"contenedor-usuarios"}>
            
            <div className={"contenedor-tabla-usuarios"}>
                <DataGrid
                    getRowId={getRowId}
                    columns={columns}
                    rows={rows} />
            </div>
        </div>

    )
}

export default TablaUsuariosSubManager;