import React from 'react';
import ContenedorReporteVentasSemanal from "../../components/reportes/manager/contenedorreporteventassemanalmanager/ContenedorReporteVentasSemanal"
import UpdatedVentaContext from '../../context/updatedVentaContext/updatedVentaContext';


function TableReportesVentasSemanalPage(props) {
    
    return (
        <>
            <ContenedorReporteVentasSemanal />
        </>
    );
}

export default TableReportesVentasSemanalPage;