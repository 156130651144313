
const FilterSelectMenuVendedor = ({handleShowPaginas,
                                      handleCheckChangeVendedores,
                                      initialShows,
                                      initialSelecteds,
                                      index,
                                      ano})=>{
    return (
        <div className="containerFilterMenu">
            <svg className="icon" width="24" height="24" viewBox="0 0 15 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg"
                 onClick={handleShowPaginas}
            >
                {
                    !initialShows[index]["showPaginas"] ?
                        <path d="M7 10L12 15L17 10H7Z" fill="currentColor"/> :
                        <path d="M7 14L12 9L17 14H7Z" fill="currentColor"/>
                }

            </svg>
            <input
                id="usuario"
                name="usuario"
                type="checkbox"
                className="form-check-input"
                checked={initialSelecteds[index]["isUsuarioSelected"]}
                onChange={handleCheckChangeVendedores}
            />
            <label className="form-check-label" htmlFor="usuario">{ano}</label>
        </div>
    );
}

export default FilterSelectMenuVendedor;
