import {Bar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {calculateAccumulatedByMonth} from "../../utils/TotalesLodash";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const meses = {
    "01":"Enero",
    "02":"Febrero",
    "03":"Marzo",
    "04":"Abril",
    "05":"Mayo",
    "06":"Junio",
    "07":"Julio",
    "08":"Agosto",
    "09":"Septiembre",
    "10":"Octubre",
    "11":"Noviembre",
    "12":"Diciembre",
}

export const getLabels = (acums) => {
    let labels = []

    Object.entries(acums).forEach(
        ([year, value]) => {
            Object.entries(value)
            .sort( ([a],[b])=>parseInt(a)-parseInt(b) )
            .forEach(
                ([month, acum]) => {
                    labels.push(year + "-" + meses[month]);
                }
            )
        }
    );

    return labels;
}

export const getValuesAcums = (acums) => {
    let valores = []

    Object.entries(acums).forEach(
        ([year, value]) => {
            Object.entries(value)
            .sort( ([a],[b])=>parseInt(a)-parseInt(b) )
            .forEach(
                ([month, acum]) => {
                    valores.push(acum);
                }
            )
        }
    );

    return valores;
}

const BarChart = ({dataMostrar, campo}) => {

    const acumulados = calculateAccumulatedByMonth(dataMostrar,campo);

    const data = {
        labels: getLabels(acumulados),
        datasets: [
            {
                label:
                    "Ganancia Final Vendedores",
                data:
                    getValuesAcums(acumulados),
                backgroundColor: "rgba(0,0,255,1)"
            }
        ]
    }

    const options = {
        plugins:{
            datalabels:{
                display:true,
                color:"white"
            }
        }
    }

    return <Bar
        data={data}
        options={options}
    />

}

export default BarChart;