import Swal from "sweetalert2"
import ApiEndpoints from "../../../../services/apiEndpoints"
import ApiService from "../../../../services/apiService"
import "./ModalEditarUsuario.css"
import React, { useEffect, useState } from 'react'

const ModalEditarUsuario = ({show, idusuario, fetchUsuarios, closeEditarUsuario}) => {
    const [dataUsuario, setDataUsuario] = useState(
        {
            "nombres": "",
            "apellidos": "",
            "email": "",
            "roles": [],
            "paginaquevendios": [],
            "activo": false,
            "password":""
        }
    )

    const fetchDataUsuario = async ()=>{
        try {
            const response = await ApiService.get(ApiEndpoints.usuario+"/"+idusuario)
            setDataUsuario(response.data);
        } catch (error) {
            Swal.fire(
                {
                    title:"Error",
                    text:"Ocurrió un error al obtener los datos",
                    icon:"error"
                }
            )
        }
    }

    const [roles, setRoles] = useState([]);
    const [paginas, setPaginas] = useState([]);

    const handleRolesChange = (event) => {
        const { name, checked } = event.target;
        const dataUsuarioUpdated = { ...dataUsuario };

        if (checked) {
            dataUsuarioUpdated['roles'].push(name);
        } else {
            dataUsuarioUpdated['roles'] = dataUsuarioUpdated['roles'].filter(item => item !== name);
        }

        setDataUsuario(dataUsuarioUpdated);
    }

    const handlePaginasChange = (event) => {
        const { name, checked } = event.target;

        const dataUsuarioUpdated = { ...dataUsuario };

        if (checked) {
            dataUsuarioUpdated['paginaquevendios'].push(name);
        } else {
            dataUsuarioUpdated['paginaquevendios'] = dataUsuarioUpdated['paginaquevendios'].filter(item => item !== name);
        }

        setDataUsuario(dataUsuarioUpdated);
    }

    const handleActivoChange = (event) => {
        const { checked } = event.target;
        const updatedData = { ...dataUsuario };
        updatedData['activo'] = checked;
        setDataUsuario(updatedData);
    }

    const fetchRoles = async () => {
        const response = await ApiService.get(ApiEndpoints.roles)
        setRoles(response.data);
    }

    const fetchPaginas = async () => {
        const response = await ApiService.get(ApiEndpoints.paginaQueVendio);
        setPaginas(response.data);
    }

    const updateField = (event, campo) => {
        const updatedDataUsuario = {
            ...dataUsuario
        }
        updatedDataUsuario[campo] = event.target.value;
        setDataUsuario(updatedDataUsuario);
    }

    const guardarUsuario = async () => {
        try {
            console.log(dataUsuario);
            const response = await ApiService.update(
                ApiEndpoints.usuario+"/"+idusuario,
                dataUsuario
            );
            fetchUsuarios();
            Swal.fire({
                title:"Usuario editado",
                text:"El usuario "+dataUsuario.email+" ha sido editado con éxito",
                icon:"success"
            })
            closeEditarUsuario();
        } catch (error) {
            Swal.fire(
                {
                    title: "Error",
                    text: "Error de conexión a la base de datos " + error.message,
                    icon:"error"
                }
            )
        }
    }

    const fetchData = () => {
        fetchDataUsuario();
        fetchRoles();
        fetchPaginas();
    }

    useEffect(
        () => {
            if(idusuario!==-1){
                fetchData();
            }
           
        }, [idusuario]
    )

    if (!show) {
        return null;
    }

    console.log(idusuario)

    return (
        <div className={"outer-modal-agregar-usuario"}>

            <div className={"contenedor-modal-agregar-usuario"}>
                <div className="contenedor-boton-cerrar-modal-agregar-usuario">
                    <button onClick={closeEditarUsuario} className="btn btn-light">X</button>
                </div>
                <div style={{
                    fontSize: "20px",
                    borderBottom: "2px gray solid"
                }}>
                    Editar Usuario
                </div>

                <div className="contenedor-input">
                    <input 
                    className="form-control" 
                    onChange={(event) => updateField(event, "nombres")} 
                    type="text" 
                    placeholder={"Nombres"} 
                    value={dataUsuario["nombres"]}
                    />
                </div>

                <div className="contenedor-input">
                    <input 
                    className="form-control" 
                    onChange={(event) => updateField(event, "apellidos")} 
                    type="text" 
                    placeholder={"Apellidos"} 
                    value={dataUsuario["apellidos"]}
                    />
                </div>

                <div className="contenedor-input">
                    <input 
                    className="form-control" 
                    onChange={(event) => updateField(event, "email")} 
                    type="email" 
                    placeholder={"Email"} 
                    value={dataUsuario["email"]}
                    />
                </div>


                <div className="contenedor-input">
                    <input 
                    className="form-control" 
                    onChange={(event) => updateField(event, "password")} 
                    type="text" 
                    placeholder={"Password"}
                    value={dataUsuario["password"]}
                    />
                </div>


                <div className="subtitulo-roles-modal-agregar-usuario">Roles</div>

                <div className="contenedor-roles-modal-agregar-usuario">
                    {
                        roles.map((role) => (
                            <div>
                                <input 
                                onChange={(event) => handleRolesChange(event)} 
                                type="checkbox" 
                                name={role.role} 
                                id={role.role}
                                
                                checked={dataUsuario["roles"].includes(role.role)}

                                />
                                <label htmlFor={role.role}>{role.role} </label>
                            </div>
                        ))
                    }
                </div>

                <div className="subtitulo-paginas-modal-agregar-usuario">Páginas que vende</div>

                <div className="contenedor-paginas-modal-agregar-usuario">
                    {
                        paginas.map((pagina) => (
                            <div>
                                <input 
                                onChange={(event) => handlePaginasChange(event)} 
                                type="checkbox" 
                                name={pagina.pagina} 
                                id={pagina.pagina} 
                                checked={dataUsuario["paginaquevendios"].includes(pagina.pagina)}
                                />
                                <label htmlFor={pagina.pagina}>{pagina.pagina} </label>
                            </div>
                        ))
                    }
                </div>

                <div className="subtitulo-estado-modal-agregar-usuario">Estado del usuario</div>

                <div className="contenedor-activo-modal-agregar-usuario">
                    <div>
                        <input 
                        onChange={(event) => handleActivoChange(event)} 
                        type="checkbox" 
                        id={"estado"} 
                        checked={dataUsuario["activo"]}
                        />
                        <label htmlFor={"estado"}>Activo</label>
                    </div>
                </div>

                <div className="contenedor-boton-agregar-modal-usuario">
                    <button onClick={guardarUsuario} type="button" className="btn btn-primary">Editar</button>
                </div>
            </div>

        </div>
    )
}

export default ModalEditarUsuario;