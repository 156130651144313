import React, {useEffect, useState} from 'react';
import AuthContext from "./authContext";
import ApiService from "../../services/apiService";

const AuthContextProvider = ({children}) => {

    const [user, setUser] = useState({});
    return (
        <AuthContext.Provider value={{user, setUser}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;