import { useEffect, useState } from "react"
import ApiEndpoints from "../services/apiEndpoints"
import ApiService from "../services/apiService"
import "./TablaPrecioPublicidad.css"
import { DataGrid } from "@mui/x-data-grid"
import ModalAgregarPublicidad from "./components/modalagregarpublicidad/ModalAgregarPublicidad"
import Swal from "sweetalert2"

const TablaPrecioPublicidad = () => {

    const [rows, setRows] = useState([]);
    const [showModalPrecioPublicidad, setShowModalPrecioPublicidad] = useState(false);

    const fetchPreciosPublicidad = async () => {
        const response = await ApiService.get(ApiEndpoints.precioPublicidadNumeroSemana);
        console.log("precio publicidad");

        console.log(response.data);
        setRows(response.data);
    }



    const updatePrecioPublicidad = async (updatedRow, originalRow) => {
        console.log(updatedRow);
        console.log(originalRow);

        const id = originalRow['id'];

        if (updatedRow['precio'] !== originalRow['precio']) {

            try {
                const response = await ApiService.update(ApiEndpoints.precioPublicidad+"/"+id,
                    {
                        
                        porcentaje: null,
                        precio: updatedRow['precio'],
                        inicioSemana: null,
                        finSemana: null,
                        idUsuario: null,
                        idPagina: null

                    }
                )
            } catch (error) {
                Swal.fire(
                    {
                        title: "Error",
                        text: "Hubo un error al actualizar el precio",
                        icon: "error"
                    }
                )
            }

            

        }

        if (updatedRow['porcentaje'] !== originalRow['porcentaje']) {
            try {
                const response = await ApiService.update(ApiEndpoints.precioPublicidad+"/"+id,
                    {
                        
                        porcentaje: updatedRow['porcentaje'],
                        precio: null,
                        inicioSemana: null,
                        finSemana: null,
                        idUsuario: null,
                        idPagina: null

                    }
                )
            } catch (error) {
                Swal.fire(
                    {
                        title: "Error",
                        text: "Hubo un error al actualizar el precio",
                        icon: "error"
                    }
                )
            }
        }

        return updatedRow;

    }

    const columns = [
        {
            field: "id",
            headerName: "Id",
            width: 50
        },
        {
            field: "nombres",
            headerName: "Nombres",
            width: 100
        },
        {
            field: "email",
            headerName: "Email",
            width: 200
        },
        {
            field: "pagina",
            headerName: "Pagina",
            width: 200
        },
        {
            field: "numeroSemana",
            headerName: "Número Semana",
            width: 100,

        },
        {
            field: "inicioSemana",
            headerName: "Inicio Semana",
            width: 100
        },
        {
            field: "finSemana",
            headerName: "Fin Semana",
            width: 100
        },
        {
            field: "porcentaje",
            headerName: "Porcentaje",
            width: 100,
            headerClassName:"editable",
            editable: true
        },
        {
            field: "precio",
            headerName: "Precio",
            width: 100,
            headerClassName:"editable",
            editable: true
        },
    ]

    useEffect(
        () => {
            fetchPreciosPublicidad();
        }, []
    )

    return (
        <div className="contenedor-tabla-precio-publicidad">
            <div className="contenedor-boton-agregar-publicidad">
                <button onClick={() => {
                    setShowModalPrecioPublicidad(true)
                }} className="btn btn-primary">Agregar Precio Publicidad</button>
            </div>

            <ModalAgregarPublicidad
                show={showModalPrecioPublicidad}
                setShowModalPrecioPublicidad={setShowModalPrecioPublicidad}
            />
            <DataGrid
                columns={columns}
                rows={rows}
                processRowUpdate={(updatedRow, originalRow) => updatePrecioPublicidad(updatedRow, originalRow)}
                sx={
                    {
                        height:"100vh"
                    }
                }
            />
        </div>
    )
}

export default TablaPrecioPublicidad;