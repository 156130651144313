import './FiltroSemanas.css';
import './FiltroSemanasImplementation'
import { useContext, useEffect, useState } from "react";
import FilterSelectMenuMonths from "./filterselectmenu/FilterSelectMenuMonths";
import FilterSelectMenuDays from "./filterselectmenu/FilterSelectMenuDays";
import FiltroSemanasImplementation from "./FiltroSemanasImplementation";
import PermitidoContext from "../context/PermitidoContext";
import { aplicarFiltros, fSemana, fVendedorPagina } from "../../utils/Filtros";
import DataContext from "../context/DataContext";
import DataMostrarContext from "../context/DataMostrarContext";
import InitialSelectsContext from "../context/InitialSelectsContext";
import { InitialSelectsVendedorPaginaContext } from "../context/InitialSelectsVendedorPaginaContext";

const filtroSemanasImpl = new FiltroSemanasImplementation();


function FiltroSemanas({ data }) {

    //const {data, setData} = useContext(DataContext);
    const { dataMostrar, setDataMostrar } = useContext(DataMostrarContext);
    const { initialSelecteds, setInitialSelecteds } = useContext(InitialSelectsContext);
    const { initialSelectsVendedorPagina, setInitialSelectsVendedorPagina } = useContext(InitialSelectsVendedorPaginaContext)

    const initializeShows = (jsonTreeFechas) => {
        return Object.entries(jsonTreeFechas).map(
            ([year, months]) => {
                return (
                    {
                        "indexYear": year,
                        "showMonths": false,
                        "months": Object.entries(months).map(
                            ([month, days]) => {
                                return (
                                    { "indexMonths": month, "showDays": false }
                                )
                            }
                        )
                        .sort((a, b) => parseInt(a.indexMonths) - parseInt(b.indexMonths)) 
                    }
                )
            }
        )
    }

    
    const [objectYears, setObjectYears] = useState({});
    const [initialShows, setInitialShows] = useState(initializeShows(objectYears));

    const handleShowMonths = (index, initialShows) => {
        const prevInitialShows = [...initialShows];
        prevInitialShows[index]["showMonths"] = !prevInitialShows[index]["showMonths"] === true;
        setInitialShows(prevInitialShows);
    }

    const handleShowDays = (index, indexMonth, initialShows) => {
        const prevInitialShows = [...initialShows];
        prevInitialShows[index]["months"][indexMonth]["showDays"] = !prevInitialShows[index]["months"][indexMonth]["showDays"] === true;
        setInitialShows(prevInitialShows);
    }

    const handleCheckChangeSeleccionarTodo = (event) => {
        const checked = event.target.checked;
        const prevInitialSelecteds = [...initialSelecteds];
        prevInitialSelecteds.forEach(
            (year) => {
                year['isYearSelected'] = checked
                year['months'].forEach(
                    (month) => {
                        month['isMonthSelected'] = checked
                        month['days'].forEach(
                            (day) => {
                                day['isDaySelected'] = checked
                            }
                        )
                    }
                )
            }
        )
        setDataMostrar(aplicarFiltros(data, initialSelecteds, initialSelectsVendedorPagina));
        setInitialSelecteds(prevInitialSelecteds);
    }

    const handleCheckChangeYears = (index) => {
        setInitialSelecteds((prevInitialSelecteds) => {
            const updatedInitialSelecteds = [...prevInitialSelecteds];
            updatedInitialSelecteds[index]["isYearSelected"] = !updatedInitialSelecteds[index]["isYearSelected"];
            updatedInitialSelecteds[index]["months"].forEach((month) => {
                month["isMonthSelected"] = updatedInitialSelecteds[index]["isYearSelected"];
                month["days"].forEach((day) => {
                    day["isDaySelected"] = updatedInitialSelecteds[index]["isYearSelected"];
                });
            });
            //setDataMostrar(fSemana(data, updatedInitialSelecteds));
            setDataMostrar(aplicarFiltros(data, initialSelecteds, initialSelectsVendedorPagina));
            return updatedInitialSelecteds;
        });
    };

    const handleCheckChangeMonths = (index, indexMonth) => {
        setInitialSelecteds((prevInitialSelecteds) => {
            const updatedInitialSelecteds = [...prevInitialSelecteds];
            updatedInitialSelecteds[index]["months"][indexMonth]["isMonthSelected"] = !updatedInitialSelecteds[index]["months"][indexMonth]["isMonthSelected"];

            updatedInitialSelecteds[index]["months"][indexMonth]["days"].forEach((day) => {
                day["isDaySelected"] = updatedInitialSelecteds[index]["months"][indexMonth]["isMonthSelected"];
            });

            // Call setDataMostrar with the updated state
            setDataMostrar(aplicarFiltros(data, initialSelecteds, initialSelectsVendedorPagina));

            return updatedInitialSelecteds;
        });
    };

    const handleCheckChangeDays = (index, indexMonth, indexDay, semana) => {
        setInitialSelecteds((prevInitialSelecteds) => {
            const updatedInitialSelecteds = [...prevInitialSelecteds];
            updatedInitialSelecteds[index]["months"][indexMonth]["days"][indexDay]["isDaySelected"] = !updatedInitialSelecteds[index]["months"][indexMonth]["days"][indexDay]["isDaySelected"];
            setDataMostrar(aplicarFiltros(data, initialSelecteds, initialSelectsVendedorPagina));
            return updatedInitialSelecteds;
        });
    };

    useEffect(
        () => {
            const newObjectYears = filtroSemanasImpl.createObjectInitializeSelectsSemanas(data); 
            setObjectYears(newObjectYears);
            setInitialShows(initializeShows(newObjectYears))
        }, [data]
    )

    return (
        <div className="containerFilter">
            <input
                type="checkbox"
                name="select-all-semanas"
                className="form-check-input"
                onChange={handleCheckChangeSeleccionarTodo}
            />
            <label htmlFor="select-all-semanas">Seleccionar todo</label>
            {initialSelecteds.length > 0 ? (
                Object.entries(objectYears).map(([ano, meses], index) => {
                    return (<>
                        <FilterSelectMenuMonths
                            handleShowMonths={() => {
                                handleShowMonths(index, initialShows)
                            }}
                            handleCheckChangeYears={() => {
                                handleCheckChangeYears(index, initialSelecteds)
                            }

                            }
                            index={index}
                            ano={ano}
                            initialShows={initialShows}
                            initialSelecteds={initialSelecteds}
                        />
                        <div className="containerMonths">
                            {
                                initialShows[index]["showMonths"] && (
                                    Object.entries(meses)
                                    .sort(([a], [b]) => parseInt(a) - parseInt(b))
                                    .map(
                                        ([mes, semanas], indexMonth) => {
                                            return (<>
                                                <FilterSelectMenuDays
                                                    handleShowDays={() => {
                                                        handleShowDays(index, indexMonth, initialShows);
                                                    }}
                                                    handleCheckChangeMonths={() => {
                                                        handleCheckChangeMonths(index, indexMonth, initialSelecteds);
                                                    }
                                                    }
                                                    index={index}
                                                    indexMonth={indexMonth}
                                                    initialShows={initialShows}
                                                    mes={mes}
                                                    initialSelecteds={initialSelecteds}
                                                />
                                                <div className="containerDays">
                                                    {initialShows[index]["months"][indexMonth]["showDays"] && (
                                                        semanas.map((semana, indexDay) => {
                                                            return (
                                                                <div className="containerFilterMenuDays">
                                                                    <input
                                                                        onChange={() =>
                                                                            handleCheckChangeDays(index, indexMonth, indexDay, initialSelecteds, semana)
                                                                        }
                                                                        id="day"
                                                                        name="day"
                                                                        type="checkbox"
                                                                        checked={initialSelecteds[index]["months"][indexMonth]["days"][indexDay]["isDaySelected"]}

                                                                    />
                                                                    <label htmlFor="day">{semana}</label>
                                                                </div>
                                                            )
                                                        })
                                                    )}
                                                </div>
                                            </>
                                            )
                                        }
                                    )
                                )
                            }
                        </div>
                    </>)
                })) : ''
            }
        </div>
    )
}

export default FiltroSemanas;