import ReporteVentasSemanal from "./components/reporteventassemanal/ReporteVentasSemanal";
import PermitidoContextProvider from "./components/context/PermitidoContextProvider";
import DataMostrarContextProvider from "./components/context/DataMostrarContextProvider";
import DataContextProvider from "./components/context/DataContextProvider";
import FiltroSemanas from "./components/filtrosemanas/FiltroSemanas";
import InitialSelectsContextProvider from "./components/context/InitialSelectsContextProvider";
import InitialSelectsVendedorPaginaContextProvider
    from "./components/context/InitialSelectsVendedorPaginaContextProvider";
import FechaInicialFinalContextProvider from "./components/context/FechaInicialFinalContextProvider";

function ContenedorReporteVentasSemanal() {
    return (
        <div className='contenedorReporteVentasSemanal' style={{
            backgroundColor: "white",
            padding: "10px",
            marginTop: "5px",

            width: '100%',
            overflowX: "auto",
            overflowY: "auto",
            maxHeight: "1000px"
        }}>
           
            <FechaInicialFinalContextProvider>
                <InitialSelectsVendedorPaginaContextProvider>
                    <InitialSelectsContextProvider>
                        <DataContextProvider>
                            <DataMostrarContextProvider>
                                <ReporteVentasSemanal />
                            </DataMostrarContextProvider>
                        </DataContextProvider>
                    </InitialSelectsContextProvider>
                </InitialSelectsVendedorPaginaContextProvider>
            </FechaInicialFinalContextProvider>
        </div>
    );
}

export default ContenedorReporteVentasSemanal;
