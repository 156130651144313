import { useEffect, useState } from "react"
import ApiEndpoints from "../services/apiEndpoints"
import ApiService from "../services/apiService"
import "./TablaPrecioPublicidadSubManager.css"
import { DataGrid } from "@mui/x-data-grid"

const TablaPrecioPublicidadSubManager = () => {

    const [rows, setRows] = useState([]);

    const fetchPreciosPublicidad = async () => {
        const response = await ApiService.get(ApiEndpoints.precioPublicidadNumeroSemana);
        console.log("precio publicidad");

        console.log(response.data);
        setRows(response.data);
    }

    const columns = [
        {
            field: "id",
            headerName: "Id",
            width: 50
        },
        {
            field: "nombres",
            headerName: "Nombres",
            width: 100
        },
        {
            field: "email",
            headerName: "Email",
            width: 200
        },
        {
            field: "pagina",
            headerName: "Pagina",
            width: 200
        },
        {
            field: "numeroSemana",
            headerName: "Número Semana",
            width: 100,

        },
        {
            field: "inicioSemana",
            headerName: "Inicio Semana",
            width: 100
        },
        {
            field: "finSemana",
            headerName: "Fin Semana",
            width: 100
        },
        {
            field: "porcentaje",
            headerName: "Porcentaje",
            width: 100,
            
        },
        {
            field: "precio",
            headerName: "Precio",
            width: 100,
            
        },
    ]

    useEffect(
        () => {
            fetchPreciosPublicidad();
        }, []
    )

    return (
        <div className="contenedor-tabla-precio-publicidad">
            
            <DataGrid
                columns={columns}
                rows={rows}
                sx={
                    {
                        height:"100vh"
                    }
                }
            />
        </div>
    )
}

export default TablaPrecioPublicidadSubManager;