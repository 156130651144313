export const listadoLabelsVentaDTOManager ={
    id:"ID",
    marcaTemporal:"Marca temporal",
    usuario:"Vendedor",
    fechaVenta:"Fecha de Venta",
    fechaVencimientoServicio:"Fecha de Vencimiento del Servicio",
    nombreApellidoDelCliente:"Nombre y Apellido del Cliente",
    usuarioClienteIPTV:"Usuario del Cliente IPTV",
    emailCliente:"Correo del Cliente",
    celularCliente:"Celular del Cliente",
    numeroDePedido:"Número de Pedido",
    mesesVendidos:"Cantidad de Meses Vendidos",
    valorPagado:"Valor Pagado por el Cliente",
    monedaPago:"Moneda de Pago del Cliente",
    pais:"Pais del Cliente",
    porDondePago:"Por Dónde Pagó",
    tipoVenta:"Tipo de Venta",
    cantidadSmart:"Cantidad de SmartOne IPTV Activados",
    paginaQueVendio:"Pagina Que Vendió",
    appsCliente:"Apps del Cliente",
    comision:"Comisión",
    gananciaNeta:"Ganancia Neta",
    gananciaNetaVendedor:"Ganancia Neta Vendedor",
    gananciaNetaManager:"Ganancia Neta Manager"
}

export const listadoLabelsVentaDTOVendedor ={
    id:"ID",
    marcaTemporal:"Marca temporal",
    fechaVenta:"Fecha de Venta",
    fechaVencimientoServicio:"Fecha de Vencimiento del Servicio",
    nombreApellidoDelCliente:"Nombre y Apellido del Cliente",
    usuarioClienteIPTV:"Usuario del Cliente IPTV",
    emailCliente:"Correo del Cliente",
    celularCliente:"Celular del Cliente",
    numeroDePedido:"Número de Pedido",
    mesesVendidos:"Cantidad de Meses Vendidos",
    valorPagado:"Valor Pagado por el Cliente",
    monedaPago:"Moneda de Pago del Cliente",
    pais:"Pais del Cliente",
    porDondePago:"Por Dónde Pagó",
    tipoVenta:"Tipo de Venta",
    cantidadSmart:"Cantidad de SmartOne IPTV Activados",
    paginaQueVendio:"Pagina Que Vendió",
    appsCliente:"Apps del Cliente",
    comision:"Comisión",
    gananciaNeta:"Ganancia Neta",
    gananciaNetaVendedor:"Ganancia Neta Vendedor",
    gananciaNetaManager:"Ganancia Neta Manager"
}

export const listadoLabelsTodosLosCampos ={
    fechaVenta:"Fecha de Venta",
    fechaVencimientoServicio:"Fecha de Vencimiento del Servicio",
    nombreApellidoDelCliente:"Nombre y Apellido del Cliente",
    usuarioClienteIPTV:"Usuario del Cliente IPTV",
    emailCliente:"Correo del Cliente",
    celularCliente:"Celular del Cliente",
    numeroDePedido:"Número de Pedido",
    mesesVendidos:"Cantidad de Meses Vendidos",
    valorPagado:"Valor Pagado por el Cliente",
    monedaPago:"Moneda de Pago del Cliente",
    pais:"Pais del Cliente",
    porDondePago:"Por Dónde Pagó",
    tipoVenta:"Tipo de Venta",
    cantidadSmart:"Cantidad de SmartOne IPTV Activados",
    paginaQueVendio:"Pagina Que Vendió",
    groupAppsCliente:"Apps del Cliente"
}

export const listadoIdLabelObligatorios = {
    fechaVenta:"Fecha de Venta",
    fechaVencimientoServicio:"Fecha de Vencimiento del Servicio",
    nombreApellidoDelCliente:"Nombre y Apellido del Cliente",
    usuarioClienteIPTV:"Usuario del Cliente IPTV",
    mesesVendidos:"Cantidad de Meses Vendidos",
    valorPagado:"Valor Pagado por el Cliente",
    monedaPago:"Moneda de Pago del Cliente",
    pais:"Pais del Cliente",
    porDondePago:"Por Dónde Pagó",
    tipoVenta:"Tipo de Venta",
    cantidadSmart:"Cantidad de SmartOne IPTV Activados",
    paginaQueVendio:"Pagina Que Vendió",
    groupAppsCliente:"Apps del Cliente"
}

export const listadoLabelsReporteVentasSemanalDTO={
    semana:"Semana",
    idUsuario:"Id Vendedor",
    usuarioNombres:"Nombres",
    usuarioApellidos:"Apellidos",
    paginaVendida:"Página que Vendió",
    inicioSemana:"Inicio de Semana",
    finSemana:"Fin de Semana",
    precioPublicidad:"Precio publicidad",
    totalValorPagadoPorElCliente:"Total Pagado Por el Cliente",
    totalComision:"Total Comisión",
    totalGananciaNetaVendedor:"Total Ganancia Neta Vendedor",
    totalGananciaNetaManager:"Total Ganancia Neta Manager",
    gananciaFinalVendedor:"Ganancia Final Vendedor"
}